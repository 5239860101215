import React, { useEffect } from 'react';
import { useAppState } from 'state';
import { Language } from 'state/types';
import CookiesNotification from 'components/Notification/CookieNotification';
import ScrollReset from 'components/ScrollReset';
import useTeachNotification from 'components/Notification/useTeachNotifications';

export default function Notifications({
  children,
}: {
  children: React.ReactNode;
}) {
  const { setLanguages } = useAppState();
  useTeachNotification();

  useEffect(
    function () {
      fetch('/data/languages.json')
        .then((res) => res.json())
        .then((data: Language[]) => {
          const dictionary = data.reduce((acc, cur) => {
            const accumulator: any = acc;
            accumulator[cur.code] = cur;
            return accumulator;
          }, {});

          setLanguages(dictionary);
        });
    },
    [setLanguages]
  );

  return (
    <>
      <ScrollReset />
      <CookiesNotification />
      {children}
    </>
  );
}
