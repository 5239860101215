import React from 'react';
import { useAppState } from 'state';
import useLocale from 'hooks/useLocale';
import { Redirect } from 'react-router-dom';

interface GuestGuardProps {
  children: React.ReactNode;
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const { user, isAuthReady } = useAppState();
  const { localePath } = useLocale();

  if (isAuthReady && user && !user.isAnonymous) {
    return <Redirect to={localePath('/app')} />;
  }

  return <>{children}</>;
}
