import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  Button,
  Divider,
  Card,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ScheduleIcon from '@material-ui/icons/ScheduleRounded';
import fetchFromAPI from 'services/fetchFromApi';
import { useAppState } from 'state';
import { packageToGA, toPrice } from 'utils/helpers';
import useAnalytics from 'hooks/useAnalytics';
import firebase from 'services/firebase';
import ProPackage from './ProPackage';
import { Package } from 'state/types';

const useStyles = makeStyles({
  time: {
    fontSize: 14,
    marginRight: 2,
  },
});

interface PackagesProps {
  proOnly?: boolean;
  simplePaidPlan?: boolean;
  onPackageSelect?: () => void;
}

export default function Packages({
  proOnly,
  simplePaidPlan,
  onPackageSelect,
}: PackagesProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();
  const { track, EventName } = useAnalytics();
  const { profile, packages, setPackages, setSelectedPackage } = useAppState();
  const { t } = useTranslation();

  useEffect(
    function () {
      async function fetchPackages() {
        setLoading(true);
        try {
          const data = await fetchFromAPI('/stripe/packages', {
            method: 'GET',
          });
          setError('');
          setPackages(data);

          const GAitems: firebase.analytics.Item[] = data.map(packageToGA);

          track(EventName.VIEW_ITEM_LIST, {
            item_list_id: 'L001',
            item_list_name: 'Packages',
            items: GAitems,
          });
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
      if (!packages.length) {
        fetchPackages();
      }
    },
    [setPackages, track, EventName, packages]
  );

  const onSelect = useCallback(
    function (selected: Package | null) {
      if (onPackageSelect) {
        onPackageSelect();
      }
      setSelectedPackage(selected);
      if (selected) {
        track(EventName.SELECT_ITEM, {
          item_list_id: 'L001',
          item_list_name: 'Packages',
          items: [packageToGA(selected)],
        });
        track(EventName.BEGIN_CHECKOUT, {
          currency: 'USD',
          value: selected.unit_amount,
          items: [packageToGA(selected)],
        });
      }
    },
    [onPackageSelect, setSelectedPackage, track, EventName]
  );

  if (loading) {
    return (
      <Box
        minHeight={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minHeight={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Typography align="center">{t('credits.loadError')}</Typography>
      </Box>
    );
  }

  if (!profile) {
    return null;
  }

  const proPackage = packages.find(function (product) {
    return product.description === 'pro';
  });

  const standardPackages = packages.filter(function (product) {
    return product.description !== 'pro';
  });

  const ProPackageInstance = (
    <ProPackage
      credits={proPackage ? proPackage.metadata.credits : ''}
      onSelect={() => onSelect(proPackage || null)}
    />
  );

  if (proOnly) {
    return <>{ProPackageInstance}</>;
  }

  let content = standardPackages.reverse();

  if (simplePaidPlan) {
    content = content.filter((pk) => pk.metadata.credits === '1300');
  }

  return (
    <>
      {!profile.stripeSubscriptionId && (
        <Card elevation={0}>
          <Box p={2}>{ProPackageInstance}</Box>
        </Card>
      )}
      {content.map((product, index) => (
        <span key={product.name}>
          <ListItem>
            <ListItemText
              primary={product.name}
              secondary={
                <Box component="span" display="flex" alignItems="center">
                  <ScheduleIcon className={classes.time} />
                  <Typography component="span">
                    {product.metadata.time}
                  </Typography>
                </Box>
              }
            />
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Button size="small" onClick={() => onSelect(product)}>
                {toPrice(
                  product.unit_amount,
                  profile.currency as Dinero.Currency
                )}
              </Button>
              <Box mt={0.3}>
                <Typography variant="body2" color="textSecondary">
                  {product.metadata.discount
                    ? `${product.metadata.discount}% ${t('credits.discount')}`
                    : ''}
                </Typography>
              </Box>
            </Box>
          </ListItem>
          {index === standardPackages.length - 1 ? null : <Divider />}
        </span>
      ))}
    </>
  );
}
