import { Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Empty from './Empty';

export default function ErrorFallback() {
  const { t } = useTranslation();
  return (
    <Empty
      title={t('errorFallback.title')}
      message={t('errorFallback.message')}
    >
      <Typography color="textSecondary">
        {t('errorFallback.persist')}{' '}
        <Link variant="inherit" href="mailto:support@lingoduck.com">
          {t('errorFallback.email')}
        </Link>
      </Typography>
    </Empty>
  );
}
