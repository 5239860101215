import React from 'react';
import {
  useTheme,
  useMediaQuery,
  Dialog as MUDialog,
  DialogProps,
} from '@material-ui/core';

export default function Dialog(props: DialogProps) {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return <MUDialog fullScreen={fullScreen} {...props} />;
}
