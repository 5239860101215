import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Link,
  makeStyles,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Logo from 'components/Logo';
import useLocale from 'hooks/useLocale';
import { TopBarRoutes } from '.';

const useStyles = makeStyles({
  drawer: {
    height: '100%',
  },
  list: {
    fontWeight: 500,
    fontSize: '1.1rem',
    paddingTop: 12,
    paddingBottom: 12,
  },
});

interface MobileNavProps {
  routes: TopBarRoutes;
  onMobileClose: () => void;
  openMobile: boolean;
}

export default function MobileNav({
  routes,
  onMobileClose,
  openMobile,
}: MobileNavProps) {
  const classes = useStyles();
  const { t, localePath } = useLocale();

  const allRoutes = [
    ...routes,
    { title: t('home.login'), to: '/login' },
    { title: t('home.signup'), to: '/welcome' },
  ];

  return (
    <Drawer
      anchor="top"
      variant="temporary"
      onClose={onMobileClose}
      open={openMobile}
      classes={{ paper: classes.drawer }}
    >
      <List
        component="nav"
        subheader={
          <CardHeader
            avatar={
              <Link component={RouterLink} to="/app">
                <Logo width={160} />
              </Link>
            }
            action={
              <IconButton color="inherit" onClick={onMobileClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            }
          />
        }
      >
        {allRoutes.map((route) => (
          <ListItem
            button
            key={localePath(route.to)}
            component={RouterLink}
            to={localePath(route.to)}
            classes={{ root: classes.list }}
            onClick={onMobileClose}
          >
            {t(route.title)}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
