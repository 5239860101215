import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Banner';
import { useAppState } from 'state';
import useAuth from 'state/firebase/useAuth';
import { onServerError } from 'utils/helpers';

export default function EmailNotifications({
  onClose,
}: {
  onClose: () => void;
}) {
  const { user } = useAppState();
  const { sendEmailVerification } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onResendPressed = useCallback(
    async function () {
      try {
        sendEmailVerification();
        enqueueSnackbar(t('notifications.emailResentMessage'), {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(t(onServerError(error)), { variant: 'error' });
      }
    },
    [sendEmailVerification, enqueueSnackbar, t]
  );

  if (!user) {
    return null;
  }

  return (
    <Banner
      actions={[
        { title: t('notifications.emailTitle'), onClick: onResendPressed },
      ]}
      onClose={onClose}
    >
      <span aria-label="bell" role="img">
        📧
      </span>
      &nbsp;&nbsp;&nbsp;{t('notifications.emailMessage', { email: user.email })}
    </Banner>
  );
}
