import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import palette from './palette';
import props from './props';
import overrides from './overrides';
import typography from './typography';
import shadows from './shadows';
import color from 'config/palette';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    headerHeight: number;
    footerHeight: number;
    mobileFooterHeight: number;
  }

  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    headerHeight: number;
    footerHeight: number;
    mobileFooterHeight: number;
  }
}

const customConfig = {
  headerHeight: 70,
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 355,
  sidebarMobileHeight: 90,
};

const theme: ThemeOptions = {
  ...customConfig,
  typography,
  props,
  overrides,
  palette,
  shadows,
};

export const darkTheme: ThemeOptions = {
  ...customConfig,
  typography,
  props,
  overrides: {
    MuiTooltip: overrides.MuiTooltip,
  },
  palette: {
    ...palette,
    type: 'dark',
    text: {
      primary: color.grey250,
      secondary: color.grey250,
      disabled: color.grey250,
      hint: color.grey250,
    },
    background: {
      paper: color.grey550,
    },
  },
};

export default theme;
