import React, { useEffect } from 'react';
import { auth } from 'services/firebase';
import useUser from 'state/firestore/useUser';
import useApp from 'state/useApp';
import { FirebaseUser } from 'state/types';
import { useAppState } from 'state';
import { getInitials } from 'utils/helpers';
import useAnalytics from 'hooks/useAnalytics';
import config from 'config';

interface AuthProps {
  children: React.ReactNode;
}

export default function Auth({ children }: AuthProps) {
  const { FCMToken } = useApp();
  const { getMyProfile, getMyProfilePrivate } = useUser();
  const { identify } = useAnalytics();
  const {
    setUser,
    _setProfile,
    setProfilePrivate,
    setIsAuthReady,
  } = useAppState();

  useEffect(
    function () {
      const unsubscribe = auth.onAuthStateChanged(async function (user) {
        if (user) {
          const providerData: string[] = [];

          user.providerData.forEach((data) => {
            if (data) {
              providerData.push(data.providerId);
            }
          });

          const firebaseUser: FirebaseUser = {
            uid: user.uid,
            emailVerified: user.emailVerified,
            providerData,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            isAnonymous: true,
          };

          if (config.isDevelopment) {
            console.log('user -> ', firebaseUser);
          }

          getMyProfilePrivate(function (profilePrivate) {
            const defaultProfilePrivate = {
              credits: 0,
            };
            if (config.isDevelopment) {
              console.log('profilePrivate -> ', profilePrivate);
            }
            FCMToken.current = profilePrivate?.FCMToken || '';
            setProfilePrivate(profilePrivate || defaultProfilePrivate);
          });

          getMyProfile(function (profile) {
            firebaseUser.isAnonymous = !profile;
            setUser(firebaseUser);

            if (profile) {
              profile.displayName = `${profile.firstName} ${profile.lastName}`;
              profile.initials = getInitials(profile.displayName);
              _setProfile(profile);
              identify(firebaseUser.uid, {
                providerId: user.providerData.join(','),
              });

              if (config.isDevelopment) {
                console.log('profile -> ', profile);
              }
            }
            setIsAuthReady(true);
          });
        } else {
          setUser(null);
          setIsAuthReady(true);
        }
      });
      return unsubscribe;
    },
    [
      FCMToken,
      setUser,
      setIsAuthReady,
      _setProfile,
      getMyProfile,
      getMyProfilePrivate,
      setProfilePrivate,
      identify,
    ]
  );

  return <>{children}</>;
}
