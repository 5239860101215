import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  body: {
    fontSize: 17,
    lineHeight: 1.9,
  },
  wrapper: {
    maxWidth: '85%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}));

export default function Why() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt={12} mb={8} id="why">
      <Container maxWidth="md">
        <div className={classes.wrapper}>
          <Typography gutterBottom variant="h2" align="center">
            {t('home.why')}
          </Typography>
          <Typography
            className={classes.body}
            variant="body1"
            color="textSecondary"
            align="center"
          >
            {t('home.because')}.
          </Typography>
        </div>
      </Container>
    </Box>
  );
}
