import React from 'react';
import clsx from 'clsx';
import {
  ListItemText,
  makeStyles,
  Avatar,
  ListItem,
  ListItemTypeMap,
} from '@material-ui/core';
import useLanguage from 'hooks/useLanguage';
import { Language } from 'state/types';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.divider}`,
    '& > img': {
      width: 'auto',
      height: 26,
    },
  },
  listAvatar: {
    marginRight: theme.spacing(1),
  },
}));

type LanguageCountryFlagProps = ListItemTypeMap<any, any>['props'] & {
  language: Language;
};

export default function LanguageCountryFlag({
  language,
  ...rest
}: LanguageCountryFlagProps) {
  const classes = useStyles();
  const { languageCodeToFlag } = useLanguage();
  return (
    <ListItem {...rest}>
      <Avatar className={clsx(classes.avatar, classes.listAvatar)}>
        {language.code && (
          <img
            alt={language.name}
            src={languageCodeToFlag(language.code)}
            onError={(e) =>
              ((e.target as any).src =
                'https://www.color-hex.com/palettes/7707.png')
            }
          />
        )}
      </Avatar>
      <ListItemText
        primary={
          language.name + (language.native ? ` (${language.native})` : '')
        }
      />
    </ListItem>
  );
}
