import React from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  videoChatContainer: {
    maxWidth: '100%',
    margin: '0 auto',
    paddingBottom: theme.spacing(5),
    '& > img': {
      width: '100%',
    },
  },
}));

export default function Chat() {
  const classes = useStyles();

  return (
    <Box my={8} id="why">
      <Container maxWidth="md">
        <div className={classes.videoChatContainer}>
          <img src="/static/images/desktop-video-chat.jpg" alt="desktop chat" />
        </div>
      </Container>
    </Box>
  );
}
