import React from 'react';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Empty from 'components/Empty';
import useLocale from 'hooks/useLocale';

export default function NotFound() {
  const { t, localePath } = useLocale();
  return (
    <Empty
      image="/static/illustrations/Something_went_wrong.png"
      title={t('errors.notFoundTitle')}
      message={t('errors.notFoundMessage')}
    >
      <Button to={localePath('/')} component={RouterLink}>
        {t('errors.notFoundAction')}
      </Button>
    </Empty>
  );
}
