/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles, darken, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Call } from 'state/types';
import { important } from 'utils/helpers';

const secondaryOverride = '#ef6b68';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    maxWidth: 400,
    minWidth: 344,
    margin: '0 auto',
  },
  actionRoot: {
    paddingBottom: 0,
  },
  actions: {
    padding: 0,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: secondaryOverride,
    '&:hover': {
      backgroundColor: darken(secondaryOverride, 0.3),
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  primarySecondary: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  secondarySecondary: {
    color: secondaryOverride,
    backgroundColor: lighten(secondaryOverride, 0.9),
    '&:hover': {
      color: darken(secondaryOverride, 0.3),
    },
  },
  successSecondary: {
    color: theme.palette.success.main,
    backgroundColor: lighten(theme.palette.success.main, 0.9),
    '&:hover': {
      color: theme.palette.success.dark,
    },
  },
  primaryDisabled: {
    color: important(theme.palette.common.white),
    backgroundColor: important(theme.palette.primary.main),
    cursor: important('default'),
  },
  secondaryDisabled: {
    color: important(theme.palette.primary.main),
    backgroundColor: important(lighten(theme.palette.primary.main, 0.9)),
    cursor: important('default'),
  },
  cursor: {
    cursor: important('default'),
  },
}));

interface TeachNotificationProps {
  disabled?: boolean;
  id: string | number;
  language: Call['language'];
  credits: Call['credits'];
  type: Call['type'];
  onAccept: () => void;
  onDeny: () => void;
}

const TeachNotification = React.forwardRef(
  (
    {
      disabled = false,
      id,
      language,
      credits,
      type,
      onAccept,
      onDeny,
    }: TeachNotificationProps,
    ref
  ) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const { t } = useTranslation();

    function handleDeny() {
      closeSnackbar(id);
      onDeny();
    }

    function handleAccept() {
      closeSnackbar(id);
      onAccept();
    }

    const image: { [key: string]: string } = {
      native: '/static/illustrations/Welcome.png',
      peer: '/static/illustrations/Congratulations.png',
      teach: '/static/illustrations/No_new_messages.png',
    };

    return (
      <Card elevation={20} ref={ref} className={classes.card}>
        <CardActions>
          <img src={image[type]} alt={type} width={85} />
          <CardActions classes={{ root: classes.actionRoot }}>
            <div>
              <Typography
                variant="subtitle1"
                className={disabled ? classes.cursor : ''}
              >
                {t(`dashboard.${type}Help`, { language })}
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={disabled ? classes.cursor : ''}
              >
                {t(`dashboard.${type}Earn`, { credits })}
              </Typography>
              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  fullWidth
                  size="small"
                  onClick={handleAccept}
                  className={clsx({
                    [classes.primary]: type === 'native',
                    [classes.secondary]: type === 'peer',
                    [classes.success]: type === 'teach',
                  })}
                  disabled={disabled}
                  classes={{ disabled: classes.primaryDisabled }}
                >
                  {t('general.accept')}
                </Button>
                <Button
                  onClick={handleDeny}
                  fullWidth
                  color="default"
                  size="small"
                  className={clsx({
                    [classes.primarySecondary]: type === 'native',
                    [classes.secondarySecondary]: type === 'peer',
                    [classes.successSecondary]: type === 'teach',
                  })}
                  classes={{ disabled: classes.secondaryDisabled }}
                  disabled={disabled}
                >
                  {t('general.deny')}
                </Button>
              </CardActions>
            </div>
          </CardActions>
        </CardActions>
      </Card>
    );
  }
);

export default TeachNotification;
