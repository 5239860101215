import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import Alert from 'components/Alert';

const useStyles = makeStyles((theme) => ({
  alert: {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.primary.dark,
    '& .MuiAlert-message': {
      margin: '0 auto',
    },
    '& .MuiAlert-action': {
      marginLeft: 'inherit',
      paddingLeft: 'inherit',
    },
  },
  action: {
    marginLeft: 5,
    opacity: 0.7,
  },
}));

interface BannerProps {
  children: React.ReactNode;
  actions?: { title: string; onClick: () => void }[];
  onActionClick?: () => void;
  onClose?: () => void;
}

export default function Banner({ children, actions, onClose }: BannerProps) {
  const classes = useStyles();

  function renderActions() {
    if (!actions) {
      return null;
    }

    return actions.map((action, index) => (
      <span key={action.title}>
        <Link
          component="button"
          onClick={action.onClick}
          underline="always"
          variant="caption"
          color="inherit"
          className={classes.action}
        >
          {action.title}
        </Link>
        {actions.length > 1 && index !== actions.length - 1 && (
          <span className={classes.action}>•</span>
        )}
      </span>
    ));
  }

  return (
    <Alert
      onClose={onClose}
      severity="info"
      variant="filled"
      icon={false}
      className={classes.alert}
    >
      <Typography component="span" variant="caption">
        {children}
      </Typography>
      {renderActions()}
    </Alert>
  );
}
