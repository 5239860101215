import { useCallback } from 'react';
import firebase, { auth, firestore } from 'services/firebase';
import FriendlyError from 'state/FriendlyError';
import { FirebaseUser, FormikUser, NewUser, OAuthType } from 'state/types';
import useUser from 'state/firestore/useUser';

export interface FirebaseAuthContextType {
  signIn: (email: string, password: string) => void;
  signUp: (
    user: FormikUser
  ) => Promise<{ user: FirebaseUser; profile: NewUser } | undefined>;
  signInWithOauth: (type: OAuthType) => Promise<boolean | undefined>;
  forgotPassword: (email: string) => void;
  updateEmail: (email: string) => Promise<string | undefined>;
  updatePassword: (currentPassword: string, newPassword: string) => void;
  logout: () => void;
  sendEmailVerification: () => void;
  deleteAccount: (reason: string, message: string) => Promise<string | void>;
  getProviderIndex: (providerId: string) => number;
  mergeProvider: (provider: firebase.auth.AuthProvider) => void;
  unmergeProvider: (providerIndex: number) => void;
  googleMergeUnmerge: () => void;
  facebookMergeUnmerge: () => void;
  hasEmailProvider: () => void;
  hasGoogleProvider: () => void;
  hasFacebookProvider: () => void;
  reauthenticateWithEmail: (password: string) => void;
  reauthenticateWithGoogle: () => void;
  reauthenticateWithFacebook: () => void;
}

export default function useAuth() {
  const { createProfile } = useUser();

  const signIn: FirebaseAuthContextType['signIn'] = useCallback(
    async (email, password) => {
      try {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        await auth.signInWithEmailAndPassword(email, password);
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const sendEmailVerification = useCallback(async () => {
    try {
      if (auth.currentUser) {
        return auth.currentUser.sendEmailVerification();
      }
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  }, []);

  const signUp: FirebaseAuthContextType['signUp'] = useCallback(
    async (profile) => {
      try {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const { user } = await auth.createUserWithEmailAndPassword(
          profile.email,
          profile.password
        );

        // isAnonymous is used differently here that its intended use case
        // It is used to confirm wheter or not the user exists in the database
        // see useFirebaseAuth to see how its being used

        if (user) {
          const providerData: string[] = [];

          user.providerData.forEach((data) => {
            if (data) {
              providerData.push(data.providerId);
            }
          });

          const firebaseUser: FirebaseUser = {
            uid: user.uid,
            emailVerified: user.emailVerified,
            providerData,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            isAnonymous: false,
          };

          user.sendEmailVerification();

          return await createProfile(firebaseUser, profile, true);
        }
        throw new FriendlyError('custom/invalid-user');
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    [createProfile]
  );

  const getProvider = useCallback(function (providerId: string) {
    switch (providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        return new firebase.auth.GoogleAuthProvider();
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.auth.FacebookAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }, []);

  const signInWithOauth: FirebaseAuthContextType['signInWithOauth'] = useCallback(
    async (type) => {
      let provider:
        | firebase.auth.FacebookAuthProvider
        | firebase.auth.GoogleAuthProvider;

      const supportedPopupSignInMethods = [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ];

      const googleProvider = new firebase.auth.GoogleAuthProvider();
      const facebookProvider = new firebase.auth.FacebookAuthProvider();

      switch (type) {
        case OAuthType.FACEBOOK:
          provider = facebookProvider;
          break;
        case OAuthType.GOOGLE:
          provider = googleProvider;
          break;
      }

      try {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        await auth.signInWithPopup(provider);
      } catch (error) {
        if (
          (error as any).code === 'auth/cancelled-popup-request' ||
          (error as any).code === 'auth/popup-closed-by-user'
        ) {
          return true;
        }

        if (
          (error as any).code ===
          'auth/account-exists-with-different-credential'
        ) {
          try {
            const signinMethods = await auth.fetchSignInMethodsForEmail(
              (error as any).email
            );

            const firstPopupProviderMethod = signinMethods.find((p) =>
              supportedPopupSignInMethods.includes(p)
            );

            if (!firstPopupProviderMethod) {
              throw new FriendlyError('auth/operation-not-allowed');
            }

            const linkedProvider = getProvider(firstPopupProviderMethod);

            linkedProvider.setCustomParameters({
              login_hint: (error as any).email,
            });

            const result = await auth.signInWithPopup(linkedProvider);

            if (result && result.user) {
              await result.user.linkWithCredential((error as any).credential);
            }
          } catch (error) {
            throw new FriendlyError((error as any).code);
          }
        } else {
          throw new FriendlyError((error as any).code);
        }
      }
    },
    [getProvider]
  );

  const forgotPassword: FirebaseAuthContextType['forgotPassword'] = useCallback(
    async (email) => {
      try {
        await auth.sendPasswordResetEmail(email);
      } catch (error) {
        if ((error as any).code === 'auth/user-not-found') {
          throw new FriendlyError('custom/invalid-user');
        }
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const reauthenticateWithPassword = useCallback(function (
    currentPassword: string
  ) {
    if (auth.currentUser && auth.currentUser.email) {
      const cred = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      return auth.currentUser.reauthenticateWithCredential(cred);
    }
  },
  []);

  const updateEmail: FirebaseAuthContextType['updateEmail'] = useCallback(
    async (email) => {
      try {
        if (auth.currentUser) {
          await auth.currentUser.reload();
          await auth.currentUser.verifyBeforeUpdateEmail(email);
        }
      } catch (error) {
        if ((error as any).code === 'auth/requires-recent-login') {
          return 'requires-recent-login';
        }
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const updatePassword: FirebaseAuthContextType['updatePassword'] = useCallback(
    async (currentPassword, newPassword) => {
      try {
        if (auth.currentUser) {
          await reauthenticateWithPassword(currentPassword);
          await auth.currentUser.updatePassword(newPassword);
          return true;
        }
        throw new FriendlyError('auth/user-not-found');
      } catch (error) {
        if ((error as any).code === 'auth/wrong-password') {
          (error as any).code = 'custom/invalid-password';
        }
        throw new FriendlyError((error as any).code);
      }
    },
    [reauthenticateWithPassword]
  );

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  }, []);

  const deleteAccount: FirebaseAuthContextType['deleteAccount'] = useCallback(
    async (reason, message) => {
      const deactivatedCollection = firestore.collection('deactivated');

      let id = '';

      try {
        if (auth.currentUser) {
          const doc = await deactivatedCollection.add({ reason, message });
          id = doc.id;
          await auth.currentUser.reload();
          await auth.currentUser.delete();
        } else {
          throw new FriendlyError('auth/user-not-found');
        }
      } catch (error) {
        if ((error as any).code === 'auth/requires-recent-login') {
          await deactivatedCollection.doc(id).delete();
          return 'requires-recent-login';
        }
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const getProviderIndex: FirebaseAuthContextType['getProviderIndex'] = useCallback(
    function (providerId) {
      if (auth.currentUser) {
        const { providerData = [] } = auth.currentUser;

        return providerData.reduce(function (acc, cur, index) {
          let accumulator = acc;
          if (cur && cur.providerId === providerId) {
            accumulator = index;
          }
          return accumulator;
        }, -1);
      }
      return -1;
    },
    []
  );

  const mergeProvider: FirebaseAuthContextType['mergeProvider'] = useCallback(
    async function (provider) {
      try {
        if (auth.currentUser) {
          await auth.currentUser.linkWithPopup(provider);
        }
      } catch (error) {
        if (
          (error as any).code === 'auth/cancelled-popup-request' ||
          (error as any).code === 'auth/popup-closed-by-user'
        ) {
          throw new FriendlyError('custom/invalid-user');
        }
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const unmergeProvider: FirebaseAuthContextType['unmergeProvider'] = useCallback(
    async function (providerIndex: number) {
      try {
        if (auth.currentUser) {
          const { providerData = [] } = auth.currentUser;
          const provider = providerData[providerIndex];
          if (provider) {
            await auth.currentUser.unlink(provider.providerId);
          }
        }
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const mergeToggle = useCallback(
    async function (provider: firebase.auth.AuthProvider, providerId: string) {
      try {
        const providerIndex = getProviderIndex(providerId);
        if (providerIndex !== -1) {
          await unmergeProvider(providerIndex);
        } else {
          await mergeProvider(provider);
        }
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    [getProviderIndex, unmergeProvider, mergeProvider]
  );

  const googleMergeUnmerge: FirebaseAuthContextType['googleMergeUnmerge'] = useCallback(
    async function () {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      return mergeToggle(googleProvider, 'google.com');
    },
    [mergeToggle]
  );

  const facebookMergeUnmerge: FirebaseAuthContextType['facebookMergeUnmerge'] = useCallback(
    async function () {
      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      return mergeToggle(facebookProvider, 'facebook.com');
    },
    [mergeToggle]
  );

  // Reauthentication: Do not use useCallback bacause will be called directly
  function hasProvider(providerId: string) {
    if (auth.currentUser) {
      const { providerData = [] } = auth.currentUser;
      return providerData.some(function (provider) {
        return (provider && provider.providerId) === providerId;
      });
    }
    return false;
  }

  function hasEmailProvider() {
    return hasProvider(firebase.auth.EmailAuthProvider.PROVIDER_ID);
  }

  function hasGooogleProvider() {
    return hasProvider(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
  }

  function hasFacebookProvider() {
    return hasProvider(firebase.auth.FacebookAuthProvider.PROVIDER_ID);
  }

  const reauthenticateWithEmail: FirebaseAuthContextType['reauthenticateWithEmail'] = useCallback(
    async function (password) {
      if (auth.currentUser && auth.currentUser.email) {
        try {
          const credential = firebase.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            password
          );
          await auth.currentUser.reauthenticateWithCredential(credential);
        } catch (error) {
          throw new FriendlyError((error as any).code);
        }
      }
    },
    []
  );

  const reauthenticateWithGoogle: FirebaseAuthContextType['reauthenticateWithGoogle'] = useCallback(
    async function () {
      if (auth.currentUser) {
        try {
          const googleProvider = new firebase.auth.GoogleAuthProvider();
          await auth.currentUser.reauthenticateWithPopup(googleProvider);
        } catch (error) {
          throw new FriendlyError((error as any).code);
        }
      }
    },
    []
  );

  const reauthenticateWithFacebook: FirebaseAuthContextType['reauthenticateWithFacebook'] = useCallback(
    async function () {
      if (auth.currentUser) {
        try {
          const facebookProvider = new firebase.auth.FacebookAuthProvider();
          await auth.currentUser.reauthenticateWithPopup(facebookProvider);
        } catch (error) {
          throw new FriendlyError((error as any).code);
        }
      }
    },
    []
  );

  return {
    signIn,
    signUp,
    signInWithOauth,
    forgotPassword,
    updateEmail,
    updatePassword,
    logout,
    deleteAccount,
    sendEmailVerification,
    getProviderIndex,
    mergeProvider,
    unmergeProvider,
    googleMergeUnmerge,
    facebookMergeUnmerge,
    hasEmailProvider,
    hasGooogleProvider,
    hasFacebookProvider,
    reauthenticateWithEmail,
    reauthenticateWithGoogle,
    reauthenticateWithFacebook,
  };
}
