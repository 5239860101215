import React from 'react';
import {
  createStyles,
  makeStyles,
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useAnalytics from 'hooks/useAnalytics';
import config from 'config';
import Routes from 'routes';
import theme from 'theme';
import palette from 'config/palette';
import AppStateProvider from 'state';
import Auth from 'components/Auth';
import Notifications from 'components/Notification';
import ErrorFallback from 'components/ErrorFallback';
import Iubenda from 'components/Iubenda';
import NotistackProvider from 'components/NotistackProvider';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'auto',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.MuiSkeleton-root': {
        backgroundColor: palette.grey150,
      },
      '.MuiAlert-root': {
        boxShadow: '0 2px 0 0 rgba(0,0,0,.1)',
      },
      '.MuiAlert-standardWarning': {
        color: 'rgb(187 133 26)',
        border: '1px solid #ffd486',
      },
      '.MuiAlert-standardError': {
        border: '1px solid #ff868a',
      },
      '.MuiAlert-standardSuccess': {
        border: '1px solid #99f7b4',
      },
      '.MuiAlert-standardInfo': {
        border: '1px solid #00b8d9',
      },
      '.MuiRating-iconEmpty': {
        color: palette.grey250,
      },
    },
  })
);

export default function App() {
  useAnalytics();
  useStyles();

  if (!config.stripe.publishableApiKey) {
    return (
      <span aria-label="oops" role="img">
        😩❌📡
      </span>
    );
  }

  const stripePromise = loadStripe(config.stripe.publishableApiKey);
  const appTheme = createMuiTheme(theme);

  return (
    <>
      {/* Temporary hack to get iubenda to not show on every page */}
      <div className="iubenda-advertising-preferences-link" />
      <HelmetProvider>
        <CookiesProvider>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <NotistackProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AppStateProvider>
                  <Elements stripe={stripePromise}>
                    <BrowserRouter>
                      <Auth>
                        <Notifications>
                          <Iubenda>
                            <Routes />
                          </Iubenda>
                        </Notifications>
                      </Auth>
                    </BrowserRouter>
                  </Elements>
                </AppStateProvider>
              </ErrorBoundary>
            </NotistackProvider>
          </ThemeProvider>
        </CookiesProvider>
      </HelmetProvider>
    </>
  );
}
