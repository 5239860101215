import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  CardHeader,
  CardActions,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useLocale from 'hooks/useLocale';

const common = [
  'Video calls',
  'No ads',
  'Premium vocabulary cards',
  'Quizlet and Anki imports',
  'Add friends to reconnect again',
  'In app language translation',
  'In video language translation',
];

export const tiers = [
  {
    title: 'One time',
    price: '9.99',
    frequency: 'one time',
    description: ['1300 credits', ...common],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
  {
    title: 'Monthly',
    subheader: 'Most popular',
    price: '9.99',
    frequency: '/month',
    description: ['2400 credits per month', ...common, 'Cancel anytime'],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
];

export default function Pricing() {
  const { localePath } = useLocale();

  return (
    <Box
      id="pricing"
      mb={10}
      style={{ backgroundColor: '#fff8d9', paddingTop: 80, paddingBottom: 80 }}
    >
      <Container maxWidth="md" component="section">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} md={6}>
              <Card
                raised={true}
                style={{ backgroundColor: tier.subheader ? '#f0efff' : '' }}
              >
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center' }}
                />
                <CardContent>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      marginBottom: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      {tier.frequency}
                    </Typography>
                  </Box>
                  <Box
                    component="ul"
                    style={{ margin: 0, padding: 0, listStyle: 'none' }}
                  >
                    {tier.description.map((line) => (
                      <Typography
                        style={{ paddingTop: 20 }}
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                        color="textSecondary"
                      >
                        {line.includes('credits') && (
                          <img
                            width={15}
                            style={{ marginBottom: -2, marginRight: 6 }}
                            src="/static/icons/coin.svg"
                            alt="coin"
                          />
                        )}
                        <span
                          style={{
                            opacity: line.includes('credits') ? 1 : 0.8,
                          }}
                        >
                          {line}
                        </span>
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
                <CardActions
                  style={{
                    paddingLeft: 50,
                    paddingRight: 50,
                    paddingBottom: 30,
                  }}
                >
                  <Button
                    component={RouterLink}
                    fullWidth
                    size="large"
                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                    to={localePath('/welcome')}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
