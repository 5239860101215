import React, { useState, useCallback } from 'react';
import {
  ChipProps,
  Popover,
  makeStyles,
  List,
  Card,
  CardHeader,
  Typography,
  Box,
  IconButton,
  Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { useTranslation } from 'react-i18next';
import Packages from 'components/Credits/Packages';
import { useAppState } from 'state';
import Checkout from 'components/Credits/Checkout';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 350,
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'center',
  },
  header: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export default function Credits(props: ChipProps) {
  const classes = useStyles();
  const { profilePrivate } = useAppState();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();

  const onClose = useCallback(
    function () {
      setAnchor(null);
    },
    [setAnchor]
  );

  const Header = (
    <Card elevation={0}>
      <CardHeader
        disableTypography
        title={
          <Typography align="center" variant="subtitle1">
            {t('credits.getCredits')}
          </Typography>
        }
        subheader={
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography color="textSecondary">{t('credits.have')} </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              component="span"
              px={0.5}
            >
              <img width={16} src="/static/icons/coin.svg" alt="coin" />{' '}
            </Box>
            <Box component="span" fontWeight="bold">
              <Typography color="textSecondary">
                {profilePrivate.credits} {t('credits.credits')}
              </Typography>
            </Box>
          </Box>
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
        classes={{ root: classes.header, title: classes.title }}
      />
    </Card>
  );

  return (
    <>
      <Checkout />
      <Chip
        icon={<img src="/static/icons/coin.svg" height={20} alt="coin" />}
        size="medium"
        label={profilePrivate.credits + ' ' + t('credits.credits')}
        onClick={(e) => setAnchor(e.currentTarget)}
        {...props}
      />
      <Popover
        anchorEl={anchor}
        open={!!anchor}
        onClose={onClose}
        classes={{ paper: classes.paper }}
      >
        <List subheader={Header}>
          <Packages onPackageSelect={onClose} />
        </List>
      </Popover>
    </>
  );
}
