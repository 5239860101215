/* eslint-disable react/display-name */
import useLanguage from 'hooks/useLanguage';
import useLocale from 'hooks/useLocale';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef } from 'react';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'state';
import useCall from 'state/firestore/useCall';
import TeachNotification from './TeachNotification';
import config from 'config';
import { profileToTopics } from 'utils/helpers';

export default function useTeachNotification() {
  const { languages, profile } = useAppState();
  const { onLanguageCallAsync } = useCall();
  const { localePath } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { languageCodeToName } = useLanguage();
  const history = useHistory();

  const showingNotification = useRef(false);

  const snooze = 'lingoduck_snooze_notification';

  const unsubscribe = useRef<any[]>([]);

  const snoozeNotification = useCallback(function () {
    const cookies = new Cookies();
    const now = new Date();
    now.setMinutes(now.getMinutes() + 1);

    cookies.set(snooze, '1', {
      path: '/',
      expires: now,
    });
  }, []);

  useEffect(
    function () {
      const onBlacklist = history.location.pathname.includes('/call');

      if (
        onBlacklist ||
        !profile ||
        !Object.keys(profile.native).length ||
        !Object.keys(languages).length
      ) {
        return undefined;
      }

      const cookies = new Cookies();
      const notificationSnooze = cookies.get(snooze);

      const topics = profileToTopics(profile);

      onLanguageCallAsync(
        profile.gender,
        profile.blockedCount,
        topics,
        function ({ roomName, language, credits, type }) {
          if (
            !onBlacklist &&
            profile.online &&
            !notificationSnooze &&
            !showingNotification.current
          ) {
            showingNotification.current = true;
            enqueueSnackbar(languageCodeToName(language), {
              autoHideDuration: 8000,
              onClose: () => (showingNotification.current = false),
              content: (key) => (
                <TeachNotification
                  onAccept={() =>
                    history.push(localePath('/call/' + roomName + '/join'))
                  }
                  onDeny={snoozeNotification}
                  language={languageCodeToName(language)}
                  credits={credits}
                  type={type}
                  id={key}
                />
              ),
            });
          }
        }
      ).then((unsub) => {
        unsubscribe.current = unsub.filter(Boolean);
      });

      return () => {
        if (config.isDevelopment) {
          console.log(
            '--> Unsubsribed from listening to languages',
            unsubscribe
          );
        }
        unsubscribe.current.length &&
          unsubscribe.current.forEach((unsub) => unsub());
      };
    },
    [
      profile,
      enqueueSnackbar,
      history,
      onLanguageCallAsync,
      snoozeNotification,
      languageCodeToName,
      localePath,
      languages,
    ]
  );
}
