import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Cookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import EmailBanner from 'components/Notification/Banners/Email';
import DesktopBanner from 'components/Notification/Banners/Desktop';
import { useAppState } from 'state';
import TopBar from './TopBar';
import WelcomeModal from './WelcomeModal';
import MobileNav from './MobileNav';

export const snooze = 'lingoduck_snooze_desktop_notif_banner';

interface DashboardProps {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  sticky: {
    zIndex: 4,
    position: 'sticky',
    top: 0,
  },
});

export default function Dashboard({ children }: DashboardProps) {
  const cookies = new Cookies();
  const desktopBannerSnooze = cookies.get(snooze);

  const classes = useStyles();
  const { user, profile, getNotification } = useAppState();
  const { t } = useTranslation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [showDesktopNotification, setShowDesktopNotification] = useState(
    !desktopBannerSnooze
  );

  const [verifyEmail, setVerifyEmail] = useState(
    user ? !user.emailVerified : false
  );

  const notification = getNotification();

  if (!profile) {
    return null;
  }

  const routes = [
    { name: t('navigation.dashboard'), to: '/app/dashboard' },
    { name: t('navigation.vocabulary'), to: '/app/vocabulary' },
    { name: t('navigation.friends'), to: '/app/friends' },
    { name: t('navigation.translate'), to: '/app/translate' },
  ];

  const secondaryRoutes = [
    {
      name: t('navigation.profile'),
      to: '/app/profile',
    },
    {
      name: t('navigation.notifications'),
      to: '/app/notifications',
      badge: profile.hasNewNotifications,
    },
  ];

  const renderBanners = () => {
    if (showDesktopNotification) {
      if (notification === 'default' && !desktopBannerSnooze) {
        return (
          <DesktopBanner
            onClose={() => {
              setShowDesktopNotification(false);
            }}
          />
        );
      }
    }

    if (verifyEmail) {
      return (
        <EmailBanner
          onClose={() => {
            setVerifyEmail(false);
          }}
        />
      );
    }
  };

  return (
    <>
      <MobileNav
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        routes={[...routes, ...secondaryRoutes]}
      />
      <div className={classes.sticky}>
        {renderBanners()}
        <WelcomeModal />
        <TopBar
          routes={routes}
          secondaryRoutes={secondaryRoutes}
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
      </div>
      <main>{children}</main>
    </>
  );
}
