import React, { useState, useEffect } from 'react';
import {
  Link,
  Portal,
  Typography,
  makeStyles,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import CloseIcon from '@material-ui/icons/CloseRounded';
import useLocale from 'hooks/useLocale';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: '50vw',
    transform: 'translateX(-50vw)',
    marginBottom: theme.spacing(4),
    outline: 'none',
    zIndex: 2000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(0, 0, 0, 2),
    margin: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export default function CookiesNotification() {
  const cookieConsent = 'lingoduck_cookie_consent';
  const cookies = new Cookies();
  const consent = cookies.get(cookieConsent);
  const classes = useStyles();
  const [open, setOpen] = useState(!consent);

  const { t, localePath } = useLocale();

  const handleClose = () => {
    const today = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);

    cookies.set(cookieConsent, 'true', {
      path: '/',
      expires: nextYear,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (!consent) {
      setOpen(true);
    }
  }, [consent]);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Paper elevation={21} className={classes.paper}>
          <Typography color="inherit">
            <span aria-label="bell" role="img">
              🍪
            </span>
            &nbsp;&nbsp;&nbsp;{t('home.cookie')}{' '}
            <Link
              component={RouterLink}
              color="primary"
              underline="none"
              to={localePath('/legal/privacy')}
            >
              {t('home.policy')}{' '}
            </Link>
            .
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Paper>
      </div>
    </Portal>
  );
}
