import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useSelected from 'hooks/useSelected';
import useLocale from 'hooks/useLocale';

interface TopBarProps {
  routes: { name: string; to: string }[];
}

const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default function TopBar({ routes }: TopBarProps) {
  const selected = useSelected();
  const { localePath } = useLocale();
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup}>
      {routes.map((route) => (
        <Button
          color={selected(localePath(route.to)) ? 'primary' : 'default'}
          variant={selected(localePath(route.to)) ? 'contained' : 'text'}
          to={localePath(route.to)}
          key={localePath(route.to)}
          component={RouterLink}
        >
          {route.name}
        </Button>
      ))}
    </div>
  );
}
