import { useCallback, useState } from 'react';
import { auth, firestore } from 'services/firebase';
import fileService from 'services/file';
import FriendlyError from 'state/FriendlyError';
import { FirebaseUser, PartialUser, User, UserPrivate } from 'state/types';
import { nanoid } from 'nanoid';

export interface FirebaseAuthContextType {
  user: FirebaseUser | null;
  setUser: React.Dispatch<React.SetStateAction<FirebaseUser | null>>;
  isAuthReady: boolean;
  setIsAuthReady: React.Dispatch<React.SetStateAction<boolean>>;
  updateProfilePhoto: (oldPhotoName: string, file: File) => Promise<string>;
  profile: User | null;
  setProfile: (partialProfile: PartialUser) => any;
  _setProfile: React.Dispatch<React.SetStateAction<User | null>>;
  profilePrivate: UserPrivate;
  setProfilePrivate: React.Dispatch<React.SetStateAction<UserPrivate>>;
}

export default function useFirebaseAuth() {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [profile, _setProfile] = useState<User | null>(null);
  const [profilePrivate, setProfilePrivate] = useState<UserPrivate>({
    credits: 0,
  });
  const [isAuthReady, setIsAuthReady] = useState(false);

  const updateProfilePhoto = useCallback(
    async (oldPhotoName: string, file: File) => {
      try {
        if (auth.currentUser) {
          const photoName = `${auth.currentUser.uid}-${file.name}-${nanoid()}`;
          const userCollection = firestore
            .collection('users')
            .doc(auth.currentUser.uid);
          const photoURL = await fileService.uploadFile(file, photoName);
          await userCollection.update({
            photoURL,
            photoName,
          });
          await fileService.deleteFile(oldPhotoName);
          return photoURL;
        }
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const setProfile: FirebaseAuthContextType['setProfile'] = useCallback(
    function (partialProfile) {
      _setProfile(function (user) {
        return { ...user, ...partialProfile };
      });
    },
    [_setProfile]
  );

  return {
    user,
    setUser,
    isAuthReady,
    setIsAuthReady,
    profile,
    setProfile,
    _setProfile,
    profilePrivate,
    setProfilePrivate,
    updateProfilePhoto,
  };
}
