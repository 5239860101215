import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useAppState } from 'state';
import {
  MenuItem,
  Menu,
  makeStyles,
  Button,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  SvgIcon,
  Tooltip,
  ListItemAvatar,
  Box,
  Avatar as MUAvatar,
  Divider,
} from '@material-ui/core';
import DropDownIcon from '@material-ui/icons/ArrowDropDownRounded';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSelected from 'hooks/useSelected';
import Avatar from 'components/Avatar';
import Switch from 'components/Switch';
import useAuth from 'state/firebase/useAuth';
import useLocale from 'hooks/useLocale';
import useUser from 'state/firestore/useUser';
import icons from 'config/icons';

const useStyles = makeStyles((theme) => ({
  logout: {
    color: theme.palette.error.main,
    marginLeft: 2,
  },
  menu: {
    minWidth: 225,
    marginTop: 0,
  },
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  endIcon: {
    marginLeft: 0,
  },
  indicator: {
    backgroundColor: theme.palette.divider,
    borderRadius: '50%',
    padding: theme.spacing(0.1),
    marginRight: theme.spacing(0.5),
    width: 8,
    height: 8,
  },
  online: {
    backgroundColor: theme.palette.success.main,
  },
  dropdownProfile: {
    '&:focus': {
      outline: 'none',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    border: 0,
  },
}));

export default function Account() {
  const { profile } = useAppState();
  const { logout } = useAuth();
  const { updateProfile } = useUser();
  const classes = useStyles();
  const selected = useSelected();
  const { t, localePath } = useLocale();
  const { enqueueSnackbar } = useSnackbar();

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  // const [
  //   coinsMobileAnchor,
  //   setCoinsMobileAnchor,
  // ] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  const changeStatus = useCallback(async () => {
    if (profile) {
      try {
        setLoading(true);
        await updateProfile({ online: !profile.online });
      } catch (error) {
        enqueueSnackbar(t('header.switchError'), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }
  }, [profile, enqueueSnackbar, t, updateProfile]);

  const menuRoutes = [
    {
      icon: icons.globeAlt,
      name: t('header.myLanguages'),
      to: '/app/settings/languages',
    },
    {
      icon: icons.cog,
      name: t('header.userSettings'),
      to: '/app/settings/profile',
    },
    {
      icon: icons.currencyDollar,
      name: t('header.credits'),
      to: '/app/credits',
    },
  ];

  if (!profile) {
    return null;
  }

  const status = profile.online ? t('header.online') : t('header.offline');

  return (
    <>
      <Tooltip title={`${profile.displayName} (${status})`}>
        <Button
          variant="text"
          color="inherit"
          size="large"
          onClick={(e) => setAnchor(e.currentTarget)}
          classes={{ endIcon: classes.endIcon, startIcon: classes.startIcon }}
          startIcon={
            <Avatar
              className={classes.avatar}
              online={profile.online}
              src={profile.photoURL}
            >
              {profile.initials}
            </Avatar>
          }
          endIcon={<DropDownIcon />}
        />
      </Tooltip>
      <Menu
        elevation={24}
        onClose={() => setAnchor(null)}
        classes={{ paper: classes.menu }}
        disableBackdropClick={false}
        anchorEl={anchor}
        open={!!anchor}
      >
        <ListItem className={classes.dropdownProfile}>
          <ListItemAvatar>
            <MUAvatar className={classes.avatar} src={profile.photoURL}>
              {profile.initials}
            </MUAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={profile.displayName}
            secondary={
              <Box display="flex" alignItems="center" mt={0.5}>
                <div
                  className={clsx(classes.indicator, {
                    [classes.online]: profile.online,
                  })}
                />
                <span>{status}</span>
              </Box>
            }
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
            secondaryTypographyProps={{
              variant: 'caption',
              color: 'textSecondary',
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t('header.online')}
            primaryTypographyProps={{
              variant: 'caption',
              color: 'textSecondary',
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              disabled={loading}
              checked={profile.online}
              onChange={changeStatus}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {menuRoutes.map((route) => {
          return (
            <MenuItem
              component={RouterLink}
              to={localePath(route.to)}
              onClick={() => setAnchor(null)}
              selected={selected(localePath(route.to))}
              key={route.name}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">{route.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemText>{route.name}</ListItemText>
            </MenuItem>
          );
        })}
        <MenuItem className={classes.logout} onClick={logout}>
          <ListItemIcon color="error">
            <SvgIcon fontSize="small" className={classes.logout}>
              {icons.logout}
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{t('auth.logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
