import firebase from 'firebase/app';

export type FirebaseSnapshot = firebase.firestore.QueryDocumentSnapshot<
  firebase.firestore.DocumentData
>;

export type Language = {
  code: string;
  name: string;
  native: string;
  country: string;
  countryCode: string;
};

export type LanguageHash = {
  [code: string]: Language;
};

export enum OAuthType {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
}

type PartUser = Pick<
  firebase.User,
  'uid' | 'displayName' | 'emailVerified' | 'email' | 'photoURL' | 'isAnonymous'
>;

export type FirebaseUser = PartUser & {
  providerData: string[];
};

export enum Level {
  beginner = 'a1',
  elementary = 'a2',
  intermediate = 'b1',
  upperIntermediate = 'b2',
  advanced = 'c1',
}

export type LevelValues = 'a1' | 'a2' | 'b1' | 'b2' | 'c1';

export enum Gender {
  male = 'male',
  female = 'female',
  other = 'other',
}

export type FormikUser = {
  native: { [key: string]: null };
  practice: { [key: string]: Level };
  fluent: { [key: string]: null };
  interests: string;
  photo: File | null;
  email: string;
  password: string;
  dob: {
    month: string;
    day: string;
    year: string;
  };
  gender: Gender | '';
  firstName: string;
  lastName: string;
  agree: boolean;
};

export type NotificationSettings = {
  nativeSpeakerOnlineDesktop: boolean;
  nativeSpeakerOnlineEmail: boolean;
  learnerSpeakerOnlineDesktop: boolean;
  learnerSpeakerOnlineEmail: boolean;
  newFriendDesktop: boolean;
  newFriendEmail: boolean;
  notFoundTeacherFoundDesktop: boolean;
  notFoundTeacherFoundEmail: boolean;
};

export type UserPrivate = {
  credits: number;
  claimedFreeCredits?: boolean;
  FCMToken?: string;
};

type BareUser = Omit<
  FormikUser,
  'password' | 'photo' | 'dob' | 'native' | 'practice' | 'email' | 'level'
>;

export type NewUser = BareUser & {
  uid: string;
  username: string;
  photoURL: string;
  photoName: string;
  online: boolean;
  dob: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
  lastActive: firebase.firestore.Timestamp;
  lastCallMadeAt: firebase.firestore.Timestamp | null;
  native: { [key: string]: null };
  practice: { [key: string]: Level };
  fluent: { [key: string]: null };
  friendsCount: number;
  pendingCount: number;
  blockedCount: number;
  hasNewNotifications: boolean;
  notificationSettings: NotificationSettings;
  onboardStep: number;

  noNotificationLanguagesNative: string[];
  noNotificationLanguagesPractice: string[];

  countryCode: string;
  timeZone: string;

  currency: string;
  timeFormat: number;

  hideOnboardBanner?: boolean;
  addedFirstFlashcard?: boolean;
  madeFirstCall?: boolean;
};

export type User = NewUser & {
  displayName: string;
  initials: string;
  stripeCustomerId: string;
  stripePaymentMethodId?: string;
  stripeSubscriptionId?: string;
  stripeSubsriptionStatus?: 'active' | 'inactive' | 'past_due';
};

export interface Package {
  id: string;
  name: string;
  price_id: string;
  description: string | null;
  unit_amount: number;
  metadata: {
    time: string;
    discount: string;
    credits: string;
  };
}

export interface CallConfiguration {
  language: string;
  subject: 'casual' | 'subject' | 'vocabulary';
  subjectDescription: string;
  vocabulary: string;
  genderMatch: 0 | 1;
  credits: number;
  connect: 'native' | 'peer' | 'teach';
}

export type Activity = {
  uid: string;
  title: string;
  body?: string;
  icon?: string;
  image: string;
  createdAt: firebase.firestore.Timestamp;
  action?: {
    add?: number;
    remove?: number;
  };
};

export type Notification = {
  uid: string;
  message: string;
  icon?: string;
  image?: string;
  createdAt: firebase.firestore.Timestamp;
  credits?: number;
  variables?: { [key: string]: string | number };
  link?: string;
};

export type PartialUser = any;

export interface CallUser {
  uid: string;
  displayName: string;
  photoUrl: string;
}

export interface Call {
  type: CallConfiguration['connect'];
  topic: string;
  language: string;
  expired: boolean;
  student: CallUser;
  teacher: CallUser;

  subject: CallConfiguration['subject'];
  subjectDescription: CallConfiguration['subjectDescription'];
  vocabulary: CallConfiguration['vocabulary'];
  genderMatch: boolean;
  gender: string;
  credits: CallConfiguration['credits'];

  roomOpen: boolean;
  roomName: string;
  roomSid: string;
  roomType: string;
  roomStatus: string;
  roomDuration: string;
  statusCallbackEvent: string;
  timeStamp: string;
  fiveMinuteWarning: boolean;
  oneMinuteWarning: boolean;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  participant1Joined: firebase.firestore.Timestamp;
  participant2Joined: firebase.firestore.Timestamp;
  createdBy: string;
}

export interface Relationship {
  to: string;
  from: string;
  toFullName: string;
  parties: string[];
  relation: 'blocked' | 'pending' | 'friends';
  deniedBy?: string;
  previousRelation?: string;
}

export type RelationshipWithUid = Relationship & {
  uid: string;
};

export interface RelationFetch {
  relationship: keyof Relationship;
  relation: Relationship['relation'];
  last?: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >;
  limit?: number;
}

export interface Friendship {
  from: string;
  to: string;
  name: string;
  relation: 'blocked' | 'pending' | 'friends';
}

export type FriendshipWithUid = Friendship & {
  uid: string;
};

export type FriendshipUser = User & {
  friendship?: FriendshipWithUid;
};

export interface Report {
  reason?: string;
  message?: string;
  reportedBy: string;
  createdAt: firebase.firestore.Timestamp;
}

export type Card = {
  id: string;
  term: string;
  definition: string;
};

export type PreDeck = {
  title: string;
  description?: string;
  cards: Card[];
  photoUrl?: string;
  source: 'template' | 'anki' | 'quizlet' | 'new' | 'csv';
  templateId?: string;
  language: string;
};

export type Deck = PreDeck & {
  createdBy: {
    uid: string;
    displayName: string | null;
  };
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
};

export type DeckWithUid = Deck & {
  uid: string;
};

export type JoinedCall = Pick<Call, 'roomOpen'> & {
  student?: CallUser;
  teacher?: CallUser;
};

export type Rating = {
  chatAgain: boolean;
  friendlyRating: number;
  qualityRating: number;
  enjoyedSessionRating: number;
  message?: string;
};
