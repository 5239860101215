import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  Hidden,
  Button,
  Link,
  makeStyles,
  IconButton,
  useScrollTrigger,
  AppBarProps,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import ArrowIcon from '@material-ui/icons/ArrowForwardRounded';
import MenuIcon from '@material-ui/icons/MenuRounded';
import Logo from 'components/Logo';
import useLocale from 'hooks/useLocale';
import useAnalytics from 'hooks/useAnalytics';
import { TopBarRoutes } from '.';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: theme.headerHeight,
  },
  link: {
    fontSize: '1.05rem',
    fontWeight: 400,
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: 0,
    marginLeft: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
  },
}));

interface TopBarProps {
  routes: TopBarRoutes;
  onMobileNavOpen: () => void;
}

export default function TopBar({ routes, onMobileNavOpen }: TopBarProps) {
  const classes = useStyles();
  const { t, localePath } = useLocale();
  const theme = useTheme();
  const { track } = useAnalytics();

  const triggered = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const options: AppBarProps = {
    elevation: triggered ? 12 : 0,
    color: 'transparent',
    style: triggered ? { backgroundColor: theme.palette.common.white } : {},
  };

  return (
    <AppBar position="fixed" {...options}>
      <Container>
        <Toolbar className={classes.toolbar} disableGutters>
          <Link
            className={classes.logoContainer}
            component={RouterLink}
            to={localePath('/app')}
          >
            <Logo width={160} />
          </Link>
          <Hidden smDown>
            <Box pl={2} />
            {routes.map((route) => (
              <Button
                key={route.title}
                variant="text"
                color="default"
                className={classes.link}
                component={RouterLink}
                to={localePath(route.to)}
              >
                {t(route.title)}
              </Button>
            ))}
          </Hidden>
          <Box flexGrow={1} />
          <Hidden mdUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon fontSize="large" />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Button
              component={RouterLink}
              to={localePath('/login')}
              variant="text"
              className={classes.link}
              color="default"
            >
              {t('home.login')}
            </Button>
            <Button
              component={RouterLink}
              endIcon={<ArrowIcon />}
              to={localePath('/#pricing')}
              className={clsx(classes.link, classes.button)}
              onClick={() => track('tutorial_begin')}
            >
              {t('home.signup')}
            </Button>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
