import { lighten } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import color from 'config/palette';

const overrides: Overrides = {
  MuiInputBase: {
    root: {
      backgroundColor: '#fff',
      '&.MuiInputBase-root.Mui-disabled': {
        backgroundColor: color.grey200,
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: '15px',
    },
    shrink: {
      transform: 'translate(0, -7.5px)',
    },
    formControl: {
      position: 'relative',
    },
  },
  MuiFormControl: {
    marginDense: {
      marginTop: 12,
      marginBottom: 8,
    },
  },
  MuiOutlinedInput: {
    root: {
      '&:hover $notchedOutline': {
        borderColor: color.grey300,
      },
      '&$disabled $notchedOutline': {
        borderColor: color.grey300,
      },
    },
    notchedOutline: {
      borderColor: color.grey300,
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: 15,
      '&.Mui-focused, &.Mui-error': {
        color: color.grey400,
      },
    },
  },
  MuiPopover: {
    paper: {
      marginTop: 5,
    },
  },
  MuiButton: {
    outlined: {
      borderColor: color.grey250,
    },
    contained: {
      color: color.indigo400,
      backgroundColor: color.indigo50,
      '&:hover': {
        backgroundColor: color.indigo100,
      },
      '&.Mui-disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        color: color.grey350,
      },
    },
    containedPrimary: {
      border: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 30,
    },
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        borderRadius: 5,
      },
    },
  },
  MuiTooltip: {
    arrow: {
      '&:before': {
        backgroundColor: color.grey700,
      },
    },
    tooltip: {
      fontSize: '0.7rem',
      borderRadius: 3,
      fontWeight: 400,
      backgroundColor: color.grey700,
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(132,140,171,0.42)',
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: color.grey150,
      border: `2px solid ${color.grey200}`,
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
  },
  MuiCardContent: {
    root: {
      padding: 18,
      '&:last-child': {
        paddingBottom: 18,
      },
    },
  },
  MuiChip: {
    root: {
      backgroundColor: color.grey150,
      color: color.indigo400,
    },
    clickable: {
      '&:hover, &:focus': {
        backgroundColor: lighten(color.grey200, 0.7),
      },
    },
  },
  MuiTab: {
    textColorPrimary: {
      '&$disabled': {
        color: color.grey250,
      },
    },
  },
};

export default overrides;
