import chunk from 'lodash.chunk';
import firebase, { auth, firestore } from 'services/firebase';
import FriendlyError from 'state/FriendlyError';
import { JoinedCall, Call } from 'state/types';
import { useCallback } from 'react';
import config from 'config';

export interface CallContextType {
  onLanguageCall: (
    gender: string,
    topics: string[],
    callback: (call: Call) => void
  ) => any[];
  onLanguageCallAsync: (
    gender: string,
    blockCount: number,
    topics: string[],
    callback: (call: Call) => void
  ) => Promise<any[]>;
  createCall: (
    call: Omit<
      Call,
      | 'updatedAt'
      | 'createdAt'
      | 'createdBy'
      | 'participant1Joined'
      | 'participant2Joined'
    >
  ) => void;
  getCall: (room: string, callback: (call: Call | null) => void) => void;
  callExists: (room: string) => Promise<Call | null>;
  joinCall: (room: string, call: JoinedCall) => void;
  setJoinedTimestamp: (
    room: string,
    participant: 'participant1' | 'participant2'
  ) => void;
  setFiveMinuteWarning: (room: string) => void;
  setOneMinuteWarning: (room: string) => void;
  updateCallExpired: (room: string) => void;
}

export default function useCall() {
  const onLanguageCall: CallContextType['onLanguageCall'] = useCallback(
    (userGender, topics, callback) => {
      const unsubscribe: any[] = [];

      chunk(topics, 10).forEach((topic) => {
        if (auth.currentUser) {
          if (config.isDevelopment) {
            console.log('--> Listening for calls with the topics ', topic);
          }

          firestore
            .collection('relationships')
            .where('relation', '==', 'blocked')
            .where('from', '==', auth.currentUser.uid)
            .get()
            .then((blocked) => {
              const blockedIds: string[] = [];

              if (!blocked.empty) {
                blocked.forEach(function (snapshot) {
                  const relationship = snapshot.data();
                  blockedIds.push(relationship.to);
                });
              }

              if (config.isDevelopment) {
                console.log('--> Blocking users with id ', blockedIds);
              }

              // const query2 = firestore
              //   .collection('calls')
              //   .where('createdBy', 'not-in', blockedIds)
              //   .where('expired', '==', false)
              //   .where('roomOpen', '==', true)
              //   .where('roomType', '==', 'go')

              const query = firestore
                .collection('calls')
                .where('topic', 'in', topic)
                // .where('createdBy', 'not-in', blockedIds)
                .where('expired', '==', false)
                .where('roomOpen', '==', true)
                .where('roomType', '==', 'go') // Twilio hook ran
                .limit(1)
                .onSnapshot(function (querySnapshot) {
                  querySnapshot.forEach(function (doc) {
                    if (doc.exists) {
                      const call = doc.data() as Call;
                      const { roomName, language, genderMatch, gender } = call;

                      if (genderMatch && userGender !== gender) {
                        return;
                      }

                      if (config.isDevelopment) {
                        console.log(
                          'Found someone that wants to learn or teach',
                          roomName,
                          language
                        );
                      }
                      callback(call);
                    }
                  });
                });
              unsubscribe.push(query);
            })
            .catch((error) => console.log(error));
        }
      });

      return unsubscribe;
    },
    []
  );

  const onLanguageCallAsync: CallContextType['onLanguageCallAsync'] = useCallback(
    async (userGender, blockCount, topics, callback) => {
      const unsubscribe: any[] = [];
      const blockedUserIds: string[] = [];
      const blockedCallIds: string[] = [];

      if (!auth.currentUser) {
        return unsubscribe;
      }

      if (blockCount) {
        const blockedUsers = await firestore
          .collection('relationships')
          .where('relation', '==', 'blocked')
          .where('from', '==', auth.currentUser.uid)
          .get();

        if (!blockedUsers.empty) {
          blockedUsers.forEach(function (snapshot) {
            const relationship = snapshot.data();
            blockedUserIds.push(relationship.to);
          });
        }

        const blockedUserIdsChunked = chunk(blockedUserIds, 10);

        const blockedCall = firestore
          .collection('calls')
          .where('expired', '==', false)
          .where('roomOpen', '==', true)
          .where('roomType', '==', 'go')
          .where('createdBy', '!=', auth.currentUser.uid);

        const blockedCallPromised = await Promise.all(
          blockedUserIdsChunked.map((ids) =>
            blockedCall.where('createdBy', '==', ids).get()
          )
        );

        blockedCallPromised.forEach((blocked) =>
          blocked.forEach((blockedCall) => {
            blockedCallIds.push(blockedCall.id);
          })
        );

        if (config.isDevelopment) {
          console.log('--> Blocking users with id ', blockedUserIds);
          console.log('--> Blocking calls with id ', blockedCallIds);
        }
      }

      chunk(topics, 10).forEach((topic: string[]) => {
        if (auth.currentUser) {
          if (config.isDevelopment) {
            console.log('--> Listening for calls with the topics ', topic);
          }

          const query = firestore
            .collection('calls')
            .where('topic', 'in', topic)
            .where('createdBy', '!=', auth.currentUser.uid)
            .where('expired', '==', false)
            .where('roomOpen', '==', true)
            .where('roomType', '==', 'go') // Twilio hook ran
            .onSnapshot(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                if (doc.exists) {
                  const call = doc.data() as Call;
                  const { roomName, language, genderMatch, gender } = call;

                  if (!blockedCallIds.includes(doc.id)) {
                    if (config.isDevelopment) {
                      console.log(
                        'Found someone that wants to learn or teach',
                        roomName,
                        language
                      );
                    }
                    if (genderMatch) {
                      if (userGender === gender) {
                        callback(call);
                      }
                    } else {
                      callback(call);
                    }
                  }
                }
              });
            });
          unsubscribe.push(query);
        }
      });

      return unsubscribe;
    },
    []
  );

  const createCall: CallContextType['createCall'] = useCallback(async function (
    call
  ) {
    try {
      if (auth.currentUser) {
        const batch = firestore.batch();

        const callRef = firestore.collection('calls').doc(call.roomName);
        const userRef = firestore.collection('users').doc(auth.currentUser.uid);

        batch.set(
          callRef,
          {
            ...call,
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
            createdBy: auth.currentUser.uid,
          },
          { merge: true }
        );

        batch.set(
          userRef,
          {
            lastCallId: call.roomName,
            lastCallMadeAt: firebase.firestore.FieldValue.serverTimestamp() as any,
          },
          { merge: true }
        );

        await batch.commit();
      }
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  },
  []);

  const getCall: CallContextType['getCall'] = useCallback(async function (
    room,
    callback
  ) {
    const callRef = firestore.collection('calls').doc(room);
    callRef.onSnapshot(function (snapshot) {
      const data = snapshot.data();
      callback((data as Call) || null);
    });
  },
  []);

  const callExists: CallContextType['callExists'] = useCallback(async function (
    room
  ) {
    try {
      const callRef = firestore.collection('calls').doc(room);
      const call = await callRef.get();
      return (call.data() as Call) || null;
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  },
  []);

  const joinCall: CallContextType['joinCall'] = useCallback(async function (
    room,
    call
  ) {
    try {
      const callRef = firestore.collection('calls').doc(room);
      await callRef.update({
        ...call,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      });
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  },
  []);

  const setJoinedTimestamp: CallContextType['setJoinedTimestamp'] = useCallback(
    async function (room, user) {
      try {
        const callRef = firestore.collection('calls').doc(room);
        await callRef.set(
          {
            [user +
            'Joined']: firebase.firestore.FieldValue.serverTimestamp() as any,
          },
          { merge: true }
        );
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const setFiveMinuteWarning: CallContextType['setFiveMinuteWarning'] = useCallback(
    async function (room) {
      try {
        const callRef = firestore.collection('calls').doc(room);
        return callRef.update({
          fiveMinuteWarning: true,
        });
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const setOneMinuteWarning: CallContextType['setFiveMinuteWarning'] = useCallback(
    async function (room) {
      try {
        const callRef = firestore.collection('calls').doc(room);
        return callRef.update({
          oneMinuteWarning: true,
        });
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const updateCallExpired: CallContextType['updateCallExpired'] = useCallback(
    async function (room) {
      try {
        const callRef = firestore.collection('calls').doc(room);
        return callRef.update({
          expired: true,
        });
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  return {
    createCall,
    getCall,
    callExists,
    joinCall,
    setJoinedTimestamp,
    onLanguageCall,
    onLanguageCallAsync,
    setFiveMinuteWarning,
    setOneMinuteWarning,
    updateCallExpired,
  };
}
