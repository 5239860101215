import React from 'react';
import Video from 'twilio-video';
import { Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Empty from 'components/Empty';

export default function UnsupportedBrowserWarning({
  children,
}: {
  children: React.ReactElement;
}) {
  const { t } = useTranslation();

  if (!Video.isSupported) {
    return (
      <Empty
        image="/static/illustrations/No_internet_connection.png"
        title={t('call.unsupportedBrowserTitle')}
        message={
          <>
            <Typography color="textSecondary">
              {t('call.unsupportedBrowserOpen')}{' '}
              <Link
                href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                target="_blank"
                rel="noopener"
              >
                {t('call.unsupportedBrowserSupported')}
              </Link>
              .
              <br />
              {t('call.unsupportedBrowserServe')}{' '}
              <Link
                href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
                target="_blank"
                rel="noopener"
              >
                {t('call.unsupportedBrowserSecure')}
              </Link>{' '}
              {t('call.unsupportedBrowserSecureExample')}
            </Typography>
          </>
        }
      />
    );
  }

  return children;
}
