import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    '& > img': {
      width: '100%',
      marginBottom: -10,
    },
  },
});

export default function Dashboard() {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <div className={classes.container}>
        <img src="/static/images/desktop-dashboard.jpg" alt="desktop chat" />
      </div>
    </Container>
  );
}
