import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  SvgIcon,
} from '@material-ui/core';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import useLocale from 'hooks/useLocale';
import Logo from 'components/Logo';
import { darkTheme } from 'theme';
import { routeIcon } from './TopBar';

const useStyles = makeStyles({
  drawerPaper: {
    width: '80%',
  },
});

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  routes: { name: string; to: string }[];
}

export default function NavBar({
  onMobileClose,
  openMobile,
  routes,
}: NavBarProps) {
  const location = useLocation();
  const { localePath } = useLocale();
  const classes = useStyles();

  return (
    <ThemeProvider theme={createMuiTheme(darkTheme)}>
      <Drawer
        anchor="left"
        variant="temporary"
        onClose={onMobileClose}
        open={openMobile}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List
          subheader={
            <Box p={2}>
              <Logo src="/logo-dark.svg" />
            </Box>
          }
        >
          {routes.map((route) => {
            const selected = Boolean(
              matchPath(location.pathname, { path: localePath(route.to) })
            );
            return (
              <ListItem
                button
                key={route.to}
                component={RouterLink}
                to={localePath(route.to)}
                onClick={onMobileClose}
              >
                <ListItemIcon>
                  <SvgIcon
                    color={selected ? 'secondary' : 'action'}
                    fontSize="small"
                  >
                    {routeIcon(route.to)}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  primary={route.name}
                  primaryTypographyProps={{
                    color: selected ? 'secondary' : 'inherit',
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </ThemeProvider>
  );
}
