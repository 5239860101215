import React, { useRef, useState } from 'react';
import {
  ListItemText,
  Popover as MUPopover,
  ListItem,
  TextField,
  InputAdornment,
  Box,
  SvgIcon,
} from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { useAppState } from 'state';
import { popularLanguages } from 'config/options';
import { useTranslation } from 'react-i18next';
import Flag from 'components/Language/FlagAvatar';
import { Language, LanguageHash } from 'state/types';
import icons from 'config/icons';

interface MUPopoverProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onSelect: (code: string) => void;
  width?: number;
  search?: boolean;
  selected: string[];
  blacklist?: string[];
}

export default function Popover({
  anchorEl,
  setAnchorEl,
  onSelect,
  width = 267,
  search = true,
  selected = [],
  blacklist = [],
}: MUPopoverProps) {
  const { t } = useTranslation();
  const { languages } = useAppState();
  const ref = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const open = Boolean(anchorEl);

  const poular = popularLanguages.map((popular) => languages[popular]);

  const sections = {
    popular: t('general.popular'),
    all: t('general.allLanguages'),
  };

  function filter(langs: LanguageHash) {
    return Object.values(langs).filter(function (language) {
      return Object.keys(language).some(function (key) {
        return (language as any)[key]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    });
  }

  function removeBlacklist(langs: Language[]) {
    return langs
      .filter(Boolean)
      .filter((language) => !blacklist.includes(language.code));
  }

  const all = Object.values(filter(languages));

  const languageSectioned = [
    sections.popular,
    ...removeBlacklist(poular),
    sections.all,
    ...removeBlacklist(all),
  ];

  const languagesList = searchTerm ? removeBlacklist(all) : languageSectioned;

  function handleClose() {
    setAnchorEl(null);
  }

  if (ref && ref.current) {
    width = ref.current.offsetWidth;
  }

  return (
    <MUPopover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {search && (
        <Box px={2}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="disabled">
                    {icons.search}
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder={t('settings.searchLanguages')}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Box>
      )}
      <FixedSizeList
        height={220}
        width={width}
        itemSize={50}
        itemCount={languagesList.length}
      >
        {function (list) {
          const option = languagesList[list.index];

          if (typeof option === 'string' || option instanceof String) {
            return (
              <ListItem style={list.style} key={list.index}>
                <ListItemText
                  primary={option}
                  primaryTypographyProps={{
                    color: 'textSecondary',
                    variant: 'caption',
                  }}
                />
              </ListItem>
            );
          }

          return (
            <Flag
              language={option}
              button
              style={list.style}
              key={list.index}
              disabled={selected.includes(option.code)}
              onClick={() => {
                onSelect(option.code);
                handleClose();
              }}
            />
          );
        }}
      </FixedSizeList>
    </MUPopover>
  );
}
