import React, { Fragment } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthGuard from 'components/AuthGuard';
import GuestGuard from 'components/GuestGuard';
import CallLayout from 'layouts/call';
import DashboardLayout from 'layouts/dashboard';
import SettingsLayout from 'layouts/settings';
import FriendsLayout from 'layouts/friends';
import HomeLayout from 'layouts/static';
import Home from 'views/static/home';
import NotFound from 'views/errors/NotFound';
import useLocale from 'hooks/useLocale';
import { supportedLanguages } from 'i18n';

interface RouteProps {
  exact?: boolean;
  path?: string | string[];
  component?: React.FC<any>;
  layout?: React.FC<any>;
  guard?: React.FC<any>;
  routes?: RouteProps[];
  extraProps?: any;
}

const redirect = (to: string) => () => {
  const { localePath } = useLocale();
  return <Redirect to={localePath(to)} />;
};

const routesConfig: RouteProps[] = [
  {
    exact: true,
    path: '/404',
    component: React.lazy(() => import('views/errors/NotFound')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: React.lazy(() => import('views/auth/Login')),
  },
  {
    guard: GuestGuard,
    path: '/register',
    component: redirect('/welcome'),
  },
  {
    guard: GuestGuard,
    path: '/welcome',
    component: React.lazy(() => import('views/auth/Register')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: React.lazy(() => import('views/auth/Forgot')),
  },
  {
    guard: AuthGuard,
    layout: CallLayout,
    path: '/call',
    routes: [
      {
        exact: true,
        path: '/call',
        component: React.lazy(() =>
          import('views/call/views/precall/Configuration')
        ),
      },
      {
        exact: true,
        path: '/call/device',
        component: React.lazy(() => import('views/call/views/precall/Device')),
      },
      {
        exact: true,
        path: '/call/:callId',
        component: React.lazy(() => import('views/call/views/call')),
      },
      {
        exact: true,
        path: '/call/:callId/join',
        component: React.lazy(() => import('views/call/views/precall/Device')),
      },
      {
        exact: true,
        path: '/call/:callId/expired',
        component: React.lazy(() => import('views/call/views/call/Expired')),
      },
      {
        exact: true,
        path: '/call/:callId/complete',
        component: React.lazy(() => import('views/call/views/rating')),
      },
    ],
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: redirect('/app/dashboard'),
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: React.lazy(() => import('views/dashboard')),
      },
      {
        exact: true,
        path: '/app/settings',
        component: redirect('/app/settings/profile'),
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/settings/profile',
        component: React.lazy(() => import('views/settings/Profile')),
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/settings/languages',
        component: React.lazy(() => import('views/settings/Languages')),
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/settings/notifications',
        component: React.lazy(() => import('views/settings/Notifications')),
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/settings/account',
        component: React.lazy(() => import('views/settings/Account')),
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/settings/pro',
        component: React.lazy(() => import('views/settings/Pro')),
      },
      {
        exact: true,
        path: '/app/notifications',
        component: React.lazy(() => import('views/notifications')),
      },
      {
        exact: true,
        path: '/app/vocabulary',
        component: React.lazy(() => import('views/vocabulary/Decks')),
      },
      {
        exact: true,
        path: '/app/vocabulary/new',
        component: React.lazy(() => import('views/vocabulary/New')),
      },
      {
        exact: true,
        path: '/app/vocabulary/:vocabularyId',
        component: React.lazy(() => import('views/vocabulary/Cards')),
      },
      {
        exact: true,
        path: '/app/credits',
        component: React.lazy(() => import('views/credits')),
      },
      {
        exact: true,
        path: '/app/translator',
        component: React.lazy(() =>
          import('views/call/views/call/Drawer/translator')
        ),
      },
      {
        exact: true,
        path: '/app/profile',
        component: React.lazy(() => import('views/profile')),
      },
      {
        exact: true,
        path: '/app/profile/:friendId',
        component: React.lazy(() => import('views/profile')),
      },
      {
        exact: true,
        layout: FriendsLayout,
        path: '/app/friends',
        component: React.lazy(() => import('views/friend')),
        extraProps: { relation: 'friends' },
      },
      {
        exact: true,
        layout: FriendsLayout,
        path: '/app/friends/pending',
        component: React.lazy(() => import('views/friend')),
        extraProps: { relation: 'pending' },
      },
      {
        exact: true,
        layout: FriendsLayout,
        path: '/app/friends/blocked',
        component: React.lazy(() => import('views/friend')),
        extraProps: { relation: 'blocked' },
      },
      {
        exact: true,
        path: '/app/translate',
        component: React.lazy(() => import('views/translate')),
      },
    ],
  },
  {
    path: '*',
    layout: HomeLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: Home,
      },
      {
        exact: true,
        path: '/learn/:lang([a-z]{2})',
        guard: GuestGuard,
        component: Home,
      },
      {
        path: '/legal',
        routes: [
          {
            exact: true,
            path: '/legal/privacy',
            component: React.lazy(() => import('views/static/legal/Privacy')),
          },
          {
            exact: true,
            path: '/legal/terms',
            component: React.lazy(() => import('views/static/legal/Terms')),
          },
          {
            exact: true,
            path: '/legal/cookie',
            component: React.lazy(() => import('views/static/legal/Cookie')),
          },
        ],
      },
      {
        component: redirect('/404'),
      },
    ],
  },
];

function renderRoutes(routes: RouteProps[], locale?: string) {
  if (!routes) {
    return null;
  }

  return (
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component || Fragment;
        const extraProps = route.extraProps || {};

        function renderRoute(props: RouteComponentProps<any>) {
          return (
            <Guard>
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes, locale)
                ) : (
                  <Component {...props} {...extraProps} />
                )}
              </Layout>
            </Guard>
          );
        }

        const localePath = locale ? `/${locale}` : '';

        return (
          <Route
            key={i}
            path={localePath + route.path}
            exact={route.exact}
            render={renderRoute}
          />
        );
      })}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

function Routes({ match }: RouteComponentProps<any>) {
  const { i18n } = useTranslation();
  const {
    params: { locale = 'en' },
  } = match;

  if (i18n.language !== locale) {
    i18n.changeLanguage(i18n.language);
  }

  return renderRoutes(routesConfig, locale);
}

function LocaleRoutes() {
  const supportedLanguageRegex = supportedLanguages.join('|');

  const supportedLanguageRoute = `(${supportedLanguageRegex})`;

  return (
    <Switch>
      <Route path={`/:locale${supportedLanguageRoute}`} component={Routes} />
      {renderRoutes(routesConfig)}
    </Switch>
  );
}

export default LocaleRoutes;
