import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScroll() {
  const { hash } = useLocation();

  useEffect(() => {
    const element = document.getElementById(hash.replace('#', ''));
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop - 120 : 0,
      });
    }, 100);
  }, [hash]);
}
