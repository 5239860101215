export const popularLanguages = [
  'en',
  'es',
  'fr',
  'de',
  'it',
  'pt',
  'ru',
  'ja',
  'ko',
  'ar',
  'hi',
  'zh-CN',
  'tr',
  'nl',
  'la',
  'sv',
  'ga',
  'el',
  'pl',
  'no',
  'he',
  'vi',
  'fi',
  'id',
  'da',
];
