import useScroll from 'hooks/useScroll';
import React, { useState } from 'react';
import MobileNav from './MobileNav';
import TopBar from './TopBar';
import Footer from './Footer';

interface HomeProps {
  children: React.ReactNode;
}

export type TopBarRoutes = {
  title: string;
  to: string;
}[];

export default function Home({ children }: HomeProps) {
  useScroll();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const routes: TopBarRoutes = [
    { title: 'home.home', to: '/' },
    { title: 'home.features', to: '/#features' },
    { title: 'home.pricing', to: '/#pricing' },
    { title: 'home.why', to: '/#why' },
  ];

  return (
    <>
      <TopBar routes={routes} onMobileNavOpen={() => setMobileNavOpen(true)} />
      {children}
      <MobileNav
        routes={routes}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Footer />
    </>
  );
}
