import { useState, useEffect, useCallback } from 'react';
import { supportsDeviceChangeEvent } from 'utils/helpers';

export function useDevices(callback?: () => void) {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  useEffect(() => {
    const getDevices = () => {
      if (supportsDeviceChangeEvent()) {
        navigator.mediaDevices.enumerateDevices().then((dvs) => {
          setDevices(dvs);
          if (callback) {
            callback();
          }
        });
      }
    };

    if (supportsDeviceChangeEvent()) {
      navigator.mediaDevices.addEventListener('devicechange', getDevices);
      getDevices();
    }

    return () => {
      if (supportsDeviceChangeEvent()) {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
      }
    };
  }, [callback]);

  return devices;
}

export function useAudioInputDevices(callback?: () => void) {
  const devices = useDevices(callback);
  return devices.filter((device) => device.kind === 'audioinput');
}

export function useVideoInputDevices(callback?: () => void) {
  const devices = useDevices(callback);
  return devices.filter((device) => device.kind === 'videoinput');
}

export function useAudioOutputDevices() {
  const devices = useDevices();
  return devices.filter((device) => device.kind === 'audiooutput');
}

// These loading states fixes the bug from twilio in which they are not waiting
// for the getDevices Promise to resolve thereby throwing a media error
export function useHasAudioInputDevices() {
  const [loading, setLoading] = useState(true);

  const stopLoading = useCallback(
    function () {
      setLoading(false);
    },
    [setLoading]
  );

  const audioDevices = useAudioInputDevices(stopLoading);

  return loading || audioDevices.length > 0;
}

export function useHasVideoInputDevices() {
  const [loading, setLoading] = useState(true);

  const stopLoading = useCallback(
    function () {
      setLoading(false);
    },
    [setLoading]
  );

  const videoDevices = useVideoInputDevices(stopLoading);

  return loading || videoDevices.length > 0;
}
