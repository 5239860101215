import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  makeStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from '@material-ui/core';
import Confetti from 'react-confetti';
import CloseIcon from '@material-ui/icons/Close';
import { useAppState } from 'state';

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    right: 0,
    top: 2,
    color: theme.palette.divider,
  },
  successContent: {
    textAlign: 'center',
  },
}));

export default function Success({ onClose }: { onClose: () => void }) {
  const classes = useStyles();
  const { profilePrivate, selectedPackage, setSelectedPackage } = useAppState();
  const { t } = useTranslation();

  const handleClose = useCallback(
    function () {
      setSelectedPackage(null);
      onClose();
    },
    [setSelectedPackage, onClose]
  );

  if (!selectedPackage) {
    return null;
  }

  return (
    <>
      <Confetti numberOfPieces={500} recycle={false} />
      <DialogTitle>
        <Typography variant="h5">{t('credits.purchaseSuccessful')}</Typography>
        <IconButton className={classes.close} onClick={handleClose}>
          <CloseIcon color="action" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.successContent}>
        <img
          height={150}
          alt="success"
          src="/static/illustrations/Success.png"
        />
        <Box mt={1} mb={2}>
          <Typography variant="subtitle1">
            {t('credits.added', { credits: selectedPackage.metadata.credits })}
          </Typography>
        </Box>
        <Typography component="span" variant="body2">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img width={20} src="/static/icons/coin.svg" alt="coin" />
            <Box pl={1}>
              {t('credits.total', { credits: profilePrivate.credits })}
            </Box>
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="medium" onClick={handleClose}>
          {t('credits.close')}
        </Button>
      </DialogActions>
    </>
  );
}
