import { messaging, functions } from 'services/firebase';
import config from 'config';
import firebaseConfig from 'config/firebase';
import { useCallback } from 'react';

export interface FirebaseMessagingContextType {
  requestPermission: () => Promise<{
    permission: NotificationPermission;
    token: string;
  }>;
  getNotification: () => NotificationPermission;
  subscribeToTopics: (topics: string[], token?: string) => any;
  unsubscribeFromTopics: (topics: string[], token?: string) => void;
  subscribeToTopic: (topic: string, token?: string) => void;
  unsubscribeFromTopic: (topic: string, token?: string) => void;
}

export default function useFirebaseMessaging() {
  const subscribeToTopic: FirebaseMessagingContextType['subscribeToTopic'] = useCallback(
    async (topic, token) => {
      // console.log('Subscribing to topic ', topic, 'with token ', token);
      if (token) {
        await functions.httpsCallable('subscribeToTopic')({
          token,
          topic,
        });
      }
    },
    []
  );

  const unsubscribeFromTopic: FirebaseMessagingContextType['unsubscribeFromTopic'] = useCallback(
    async (topic, token) => {
      if (token) {
        await functions.httpsCallable('unsubscribeFromTopic')({
          token,
          topic,
        });
      }
    },
    []
  );

  const subscribeToTopics: FirebaseMessagingContextType['subscribeToTopics'] = useCallback(
    (topics, token) => {
      return Promise.all(topics.map((topic) => subscribeToTopic(topic, token)));
    },
    [subscribeToTopic]
  );

  const unsubscribeFromTopics: FirebaseMessagingContextType['unsubscribeFromTopics'] = useCallback(
    (topics, token) => {
      return Promise.all(
        topics.map((topic) => unsubscribeFromTopic(topic, token))
      );
    },
    [unsubscribeFromTopic]
  );

  const deleteToken = useCallback(() => {
    if (Notification.permission !== 'granted') return;
    if (messaging) {
      messaging.deleteToken();
    }
  }, []);

  const getToken = useCallback(async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window && messaging) {
      const options = new URLSearchParams({
        apiKey: firebaseConfig.apiKey,
        projectId: firebaseConfig.projectId,
        messagingSenderId: firebaseConfig.messagingSenderId,
        appId: firebaseConfig.appId,
      });

      let serviceWorkerRegistration: ServiceWorkerRegistration | undefined;

      serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();

      if (!serviceWorkerRegistration) {
        serviceWorkerRegistration = await navigator.serviceWorker.register(
          '/firebase-messaging-sw.js?' + options.toString(),
          {
            scope: '/firebase-cloud-messaging-push-scope',
          }
        );
      }

      const messageToken = await messaging.getToken({
        vapidKey: config.google.cloudMessaging.vapidKey,
        serviceWorkerRegistration,
      });

      return messageToken;
    }
    return '';
  }, []);

  const requestPermission: FirebaseMessagingContextType['requestPermission'] = useCallback(async () => {
    let permission: NotificationPermission = 'denied';
    let token = '';

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      permission = await window.Notification.requestPermission();

      if (permission !== 'granted') {
        console.log('Unable to subscribe to WebPush: no permissions');
      } else {
        token = await getToken();
        await functions.httpsCallable('setFCMToken')({ FCMToken: token });
      }

      return { permission, token };
    }
    return { permission, token };
  }, [getToken]);

  const getNotification: FirebaseMessagingContextType['getNotification'] = useCallback(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      return Notification.permission;
    } else {
      return 'denied';
    }
  }, []);

  // function listenForMessages() {
  //   if (messaging) {
  //     messaging.onMessage((payload) => {
  //       const title = payload.notification.title;
  //       const options = {
  //         body: payload.notification.body,
  //         icon: payload.notification.icon,
  //         actions: [
  //           {
  //             action: payload.fcmOptions.link,
  //             title: 'Book Appointment',
  //           },
  //         ],
  //       };
  //       makeToast(title, options);
  //     });
  //   }
  // }

  return {
    subscribeToTopic,
    unsubscribeFromTopic,
    unsubscribeFromTopics,
    requestPermission,
    deleteToken,
    getNotification,
    subscribeToTopics,
  };
}
