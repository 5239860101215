export default {
  green100: '#EBFFF1',
  green200: '#CCFFDA',
  green300: '#99F7B4',
  green400: '#46DB83',
  green500: '#28BF6C',
  green600: '#2E7A53',
  green700: '#1E4840',

  indigo50: '#EFEFFF',
  indigo100: '#EBEEFF',
  indigo200: '#BFC4FF',
  indigo300: '#8F97FF',
  indigo400: '#6361FA',
  indigo500: '#414DD4',
  indigo600: '#1B1A82',
  indigo700: '#0C084D',

  grey50: '#FAFBFF',
  grey100: '#F5F9FF',
  grey150: '#EDF0F7',
  grey200: '#DDE1F0',
  grey250: '#C8CEE3',
  grey300: '#A9B0C9',
  grey350: '#848CAB',

  grey400: '#606782',
  grey450: '#5B627D',
  grey500: '#475066',
  grey550: '#374054',
  grey600: '#2D3445',
  grey650: '#232938',
  grey700: '#0F1F29',

  red500: '#EF6B68',
};
