import { useRef, useState } from 'react';
import {
  LanguageHash,
  Package,
  CallConfiguration,
  Call,
  CallUser,
} from './types';

export interface AppContextType {
  FCMToken: React.MutableRefObject<string>;
  languages: LanguageHash;
  setLanguages: React.Dispatch<React.SetStateAction<LanguageHash>>;
  packages: Package[];
  setPackages: React.Dispatch<React.SetStateAction<Package[]>>;
  selectedPackage: Package | null;
  setSelectedPackage: React.Dispatch<React.SetStateAction<Package | null>>;
  activeDeckName: string;
  setActiveDeckName: React.Dispatch<React.SetStateAction<string>>;
  callConfiguration: CallConfiguration | null;
  setCallConfiguration: React.Dispatch<
    React.SetStateAction<CallConfiguration | null>
  >;
  call: Call | null;
  setCall: React.Dispatch<React.SetStateAction<Call | null>>;
  callExpired: boolean;
  setCallExpired: React.Dispatch<React.SetStateAction<boolean>>;
  callProgress: boolean;
  setCallProgress: React.Dispatch<React.SetStateAction<boolean>>;
  ratingUser: CallUser;
  setRatingUser: React.Dispatch<React.SetStateAction<CallUser>>;
}

export default function useApp() {
  const FCMToken = useRef('');
  const [languages, setLanguages] = useState<LanguageHash>({});
  const [packages, setPackages] = useState<Package[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [activeDeckName, setActiveDeckName] = useState('');
  const [
    callConfiguration,
    setCallConfiguration,
  ] = useState<CallConfiguration | null>(null);

  const [call, setCall] = useState<Call | null>(null);
  const [callExpired, setCallExpired] = useState(false);
  const [callProgress, setCallProgress] = useState(false);
  const [ratingUser, setRatingUser] = useState<CallUser>({
    uid: '',
    photoUrl: '',
    displayName: '',
  });

  return {
    FCMToken,
    languages,
    setLanguages,
    packages,
    setPackages,
    selectedPackage,
    setSelectedPackage,
    activeDeckName,
    setActiveDeckName,
    callConfiguration,
    setCallConfiguration,
    call,
    setCall,
    callExpired,
    setCallExpired,
    callProgress,
    setCallProgress,
    ratingUser,
    setRatingUser,
  };
}
