import React from 'react';
import { useTranslation } from 'react-i18next';
import NestedRoutes from 'components/NestedRoutes';
import { useAppState } from 'state';

interface FriendsProps {
  children: React.ReactNode;
}

export default function Friends({ children }: FriendsProps) {
  const { profile } = useAppState();
  const { t } = useTranslation();

  const routes = [
    {
      name: t('navigation.friends'),
      to: '/app/friends',
      badge: profile?.friendsCount,
    },
    {
      name: t('navigation.pending'),
      to: '/app/friends/pending',
      badge: profile?.pendingCount,
    },
    {
      name: t('navigation.blocked'),
      to: '/app/friends/blocked',
      badge: profile?.blockedCount,
    },
  ];

  return <NestedRoutes routes={routes}>{children}</NestedRoutes>;
}
