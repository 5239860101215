import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardElement } from '@stripe/react-stripe-js';
import { Box, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import FormikInput from 'formik/FormikInput';
import FormikAlert from 'formik/FormikAlert';

const useStyles = makeStyles({
  cardElement: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#A9B0C9',
    borderStyle: 'solid',
    padding: 9,
    width: '100%',
    boxSizing: 'border-box',
  },
});

interface PaymentFormProps {
  setStatus: (status: any) => void;
  setDisabled: (disable: boolean) => void;
}

export default function PaymentForm({
  setStatus,
  setDisabled,
}: PaymentFormProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'DM Sans, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '15px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <>
      <FormikAlert name="checkoutError" severity="error" />
      <Box display="flex">
        <FormikInput
          type="text"
          name="firstName"
          label={t('auth.firstName')}
          placeholder={t('auth.firstNamePlaceholder')}
        />
        <Box width={20} height={1} />
        <FormikInput
          type="text"
          name="lastName"
          label={t('auth.lastName')}
          placeholder={t('auth.lastNamePlaceholder')}
        />
      </Box>
      <FormikInput
        name="email"
        type="email"
        label={t('auth.email')}
        placeholder={t('auth.emailPlaceholder')}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel shrink>{t('credits.creditCard')}</InputLabel>
        <CardElement
          className={classes.cardElement}
          options={cardStyle}
          onChange={(event) => {
            setDisabled(event.empty);
            setStatus({
              checkoutError: event.error ? event.error.message : '',
            });
          }}
        />
      </FormControl>
    </>
  );
}
