import React from 'react';
import {
  Container,
  Grid,
  Box,
  Hidden,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Navbar from './Navbar';
import TopBar from './TopNav';

interface NestedRoutesProps {
  routes: { name: string; to: string }[];
  children: React.ReactNode;
}

export default function NestedRoutes({ routes, children }: NestedRoutesProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <Grid container spacing={mobile ? 0 : 8}>
        <Hidden smDown>
          <Grid item xs={3} md={2}>
            <Box mt={1}>
              <Navbar routes={routes} />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={10}>
          <Hidden mdUp>
            <Box my={2}>
              <TopBar routes={routes} />
            </Box>
          </Hidden>
          <Box mt={3}>{children}</Box>
        </Grid>
      </Grid>
    </Container>
  );
}
