import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Cookies } from 'react-cookie';
import Banner from 'components/Banner';
import { useAppState } from 'state';
import { useSnackbar } from 'notistack';
import { snooze } from 'layouts/dashboard';
import { profileToTopics } from 'utils/helpers';

export default function DesktopNotifications({
  onClose,
}: {
  onClose: () => void;
}) {
  const cookies = new Cookies();

  const {
    user,
    profile,
    profilePrivate,
    requestPermission,
    subscribeToTopics,
    setProfilePrivate,
  } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onEnablePressed = useCallback(
    async function () {
      const { permission, token } = await requestPermission();

      onClose();
      cookies.remove(snooze);

      if (profile && profilePrivate) {
        setProfilePrivate((prev) => ({ ...prev, FCMToken: token }));
        const topics = profileToTopics(profile);
        try {
          await subscribeToTopics(topics, token);
          if (permission === 'granted') {
            enqueueSnackbar(t('notifications.desktopEnableSuccess'), {
              variant: 'success',
              autoHideDuration: 2000,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      profile,
      profilePrivate,
      setProfilePrivate,
      requestPermission,
      onClose,
      subscribeToTopics,
      cookies,
      t,
      enqueueSnackbar,
    ]
  );

  const onResendRemind = useCallback(
    function () {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      cookies.set(snooze, '1', {
        path: '/',
        expires: tomorrow,
      });
      onClose();
    },
    [onClose, cookies]
  );

  if (!user || !profile) {
    return null;
  }

  return (
    <Banner
      actions={[
        { title: t('notifications.desktopTitle'), onClick: onEnablePressed },
        { title: t('notifications.desktopRemind'), onClick: onResendRemind },
      ]}
    >
      <span aria-label="bell" role="img">
        🔔
      </span>
      &nbsp;&nbsp;&nbsp; {t('notifications.desktopMessage')}
    </Banner>
  );
}
