import { supportedLanguages } from 'i18n';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export default function useLocale() {
  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();

  function localePath(path: string) {
    const hasLocaleInUrl = supportedLanguages.some((lang) => {
      const paths = pathname.split('/');

      const language = paths[1];

      return lang === language;
    });

    let route = '';

    if (hasLocaleInUrl) {
      route = `/${i18n.language}`;
    }

    return `${route}${path}`;
  }

  return { t, localePath };
}
