import Axios, { AxiosRequestConfig } from 'axios';
import env from 'config';
import { auth } from 'services/firebase';

const fetchFromAPI = async (url: string, config?: AxiosRequestConfig) => {
  const { method, data }: AxiosRequestConfig = {
    method: 'POST',
    ...config,
  };
  const axios = Axios.create({
    baseURL: env.baseUrl,
    timeout: 30000,
  });
  const user = auth.currentUser;
  const token = user && (await user.getIdToken());
  const response = await axios(url, {
    method,
    headers: { Authorization: `Bearer ${token}` },
    data,
  });
  return response.data;
};

export default fetchFromAPI;
