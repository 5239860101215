import { matchPath, useLocation } from 'react-router-dom';

export default function useSelected(): (
  path: string,
  exact?: boolean
) => boolean {
  const location = useLocation();
  return (path, exact = false) =>
    Boolean(matchPath(location.pathname, { path, exact }));
}
