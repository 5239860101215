import React from 'react';
import {
  CircularProgress,
  CircularProgressProps,
  makeStyles,
  styled,
} from '@material-ui/core';
import Logo from 'components/Logo';

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.divider,
  },
  top: {
    color: theme.palette.primary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export function FacebookCircularProgress(props: CircularProgressProps) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'fixed',
  left: 0,
  top: 0,
  padding: theme.spacing(3),
  zIndex: 2000,
}));

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
}));

const MainLogo = styled(Logo)({
  width: 180,
  maxWidth: '100%',
});

export default function SlashScreen({
  hideLogo = false,
}: {
  hideLogo?: boolean;
}) {
  return (
    <Container>
      {!hideLogo && (
        <LogoContainer>
          <MainLogo width={160} src="/logo.svg" />
        </LogoContainer>
      )}
      <FacebookCircularProgress size={30} />
    </Container>
  );
}
