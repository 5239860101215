import React from 'react';

export default function Logo({
  src = '/logo.svg',
  width = 130,
  ...rest
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      alt="App Logo"
      width={width}
      height={Number(width) / 4}
      src={`/static/logo${src}`}
      {...rest}
    />
  );
}
