import React, { useContext } from 'react';
import useFirebaseAuth, { FirebaseAuthContextType } from './useFirebaseAuth';
import useFirebaseMessaging, {
  FirebaseMessagingContextType,
} from './useFirebaseMessaging';
import useTwilio, { TwilioContextType } from './useTwilio';
import useApp, { AppContextType } from './useApp';

interface StateContextType
  extends FirebaseAuthContextType,
    AppContextType,
    FirebaseMessagingContextType,
    TwilioContextType {}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const StateContext = React.createContext<StateContextType>(null!);

export function AppStateProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const contextValue: StateContextType = {
    ...useFirebaseAuth(),
    ...useFirebaseMessaging(),
    ...useTwilio(),
    ...useApp(),
  };

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

export default AppStateProvider;
