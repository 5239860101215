import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { TwilioError } from 'twilio-video';
import UnsupportedBrowserWarning from 'views/call/components/UnsupportedBrowserWarning';
import { VideoProvider } from 'views/call/components/VideoProvider';
import useHeight from 'views/call/hooks/useHeight';
import useConnectionOptions from 'views/call/utils/useConnectionOptions';
import palette from 'config/palette';

interface CallProps {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: palette.grey600,
  },
});

export default function Call({ children }: CallProps) {
  const classes = useStyles();
  const height = useHeight();
  const { enqueueSnackbar } = useSnackbar();
  const connectionOptions = useConnectionOptions();

  const onError = useCallback(
    function (error: TwilioError) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    [enqueueSnackbar]
  );

  const onDisconnect = useCallback(function (room, error) {
    console.log('🔌 Disconnected...', room.sid, error);
    room.localParticipant.tracks.forEach((publication: any) => {
      publication.track.stop();
      const attachedElements = publication.track.detach();
      console.log('🔌  Unsubscribed from: ' + publication.track);
      attachedElements.forEach((element: any) => element.remove());
    });
  }, []);

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider
        options={connectionOptions}
        onError={onError}
        onDisconnect={onDisconnect}
      >
        <div className={classes.root} style={{ height }}>
          {children}
        </div>
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
}
