export default class FriendlyError extends Error {
  public code: string;
  public message: string;

  constructor(code: string, message?: string) {
    super(message);
    this.code = this.sanitize(code);
    this.message = this.sanitize(code);
  }

  sanitize(code: string) {
    switch (code) {
      case 'auth/invalid-password':
        return 'auth/user-not-found';
      case 'auth/wrong-password':
        return 'auth/user-not-found';
      case 'auth/invalid-email':
        return 'auth/user-not-found';
      case 'auth/user-disabled':
        return 'auth/user-not-found';
      case 'auth/user-not-found':
        return 'auth/user-not-found';
      case 'auth/email-already-in-use':
        return code;
      case 'custom/username-already-in-use':
        return code;
      case 'auth/operation-not-allowed':
        return code;
      case 'auth/weak-password':
        return code;
      case 'auth/too-many-requests':
        return code;
      case 'custom/invalid-user':
        return code;
      case 'custom/invalid-password':
        return code;
      default:
        return 'custom/default';
    }
  }
}
