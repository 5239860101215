import config from 'config';
import firebaseConfig from 'config/firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/performance';
import 'firebase/functions';
import 'firebase/remote-config';

try {
  firebase.initializeApp(firebaseConfig);
} catch (error) {
  console.log('❌ Initialization failed');
}

export const firestore = firebase.firestore();

export const analytics = firebase.analytics();

export const database = firebase.database();

export const performance = firebase.performance();

export const remoteConfig = firebase.remoteConfig();

export const storage = firebase.storage();

export const auth = firebase.auth();

export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const functions = firebase.functions();

if (config.node.isDevelopment) {
  functions.useEmulator('localhost', 5000);
}

remoteConfig.settings = {
  minimumFetchIntervalMillis: config.isDevelopment ? 120000 : 3600000,
  fetchTimeoutMillis: 3600000,
};

remoteConfig.fetchAndActivate().then(() => {
  console.log('🚀 Blastoff!');
});

(window as any).__firebase__test = firebase;

export default firebase;
