import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppState } from 'state';
import useLocale from 'hooks/useLocale';
import SplashScreen from 'components/SplashScreen';

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isAuthReady } = useAppState();
  const { localePath } = useLocale();

  if (!isAuthReady) {
    return <SplashScreen />;
  }

  if (!user) {
    return <Redirect to={localePath('/')} />;
  }

  return <>{children}</>;
}
