import { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import config from 'config';
import firebase, { analytics } from 'services/firebase';

export default function useAnalytics() {
  const { sentry, tagManager, isProduction } = config;
  const tagAppId = tagManager.id || '';

  const renderAnalytics = isProduction ? tagAppId : '';

  const EventName = firebase.analytics.EventName;

  const identify = useCallback(
    function (name: string, options?: any) {
      if (renderAnalytics) {
        TagManager.initialize({
          gtmId: tagAppId,
          dataLayer: {
            event: 'identify',
            name,
            options,
          },
        });
      }

      analytics.setUserId(name);
      analytics.setUserProperties(options);
    },
    [renderAnalytics, tagAppId]
  );

  const track = useCallback(
    function (name: string, options?: any) {
      if (renderAnalytics) {
        TagManager.initialize({
          gtmId: tagAppId,
          dataLayer: {
            event: 'track',
            name,
            options,
          },
        });
        analytics.logEvent(name, options);
      }
    },
    [renderAnalytics, tagAppId]
  );

  // Not used because firebase analytics tracks page automatically
  const page = useCallback(
    function (name: string, options?: any) {
      if (renderAnalytics) {
        TagManager.initialize({
          gtmId: tagAppId,
          dataLayer: {
            event: 'page',
            name,
            options,
          },
        });
      }
    },
    [renderAnalytics, tagAppId]
  );

  useEffect(() => {
    if (renderAnalytics) {
      // Tag Manager
      if (tagManager.id) {
        TagManager.initialize({
          gtmId: tagManager.id,
        });
      }

      // Sentry
      if (sentry.dsn) {
        Sentry.init({
          dsn: sentry.dsn,
          release: 'lingoduck@' + process.env.npm_package_version,
          integrations: [new Integrations.BrowserTracing()],
          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0,
          // beforeSend(event) {
          //   event.exception &&
          //     Sentry.showReportDialog({ eventId: event.event_id });
          //   return event;
          // },
        });
      }
    }
  }, [renderAnalytics, sentry.dsn, tagManager.id]);

  return {
    identify,
    track,
    page,
    EventName,
  };
}
