export default {
  isDevelopment:
    process.env.REACT_APP_ENV === 'development' || !process.env.REACT_APP_ENV,
  isProduction: process.env.REACT_APP_ENV === 'production',
  node: {
    isProduction: process.env.NODE_ENV === 'production',
    isDevelopment: process.env.NODE_ENV === 'development',
    isTest: process.env.NODE_ENV === 'test',
  },
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  tagManager: {
    id: process.env.REACT_APP_TAG_MANAGER_ID,
  },
  stripe: {
    publishableApiKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  },
  paypal: {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  },
  google: {
    maps: {
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
    adsense: {
      clientKey: process.env.REACT_APP_ADSENSE_CLIENT_KEY,
      slotKey: process.env.REACT_APP_SLOT_KEY,
    },
    cloudMessaging: {
      vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
    },
  },
};
