import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
  SvgIcon,
  fade,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import icons from 'config/icons';

const useStyles = makeStyles({
  title: {
    lineHeight: 1.3,
  },
  description: {
    lineHeight: 1.5,
    marginTop: 4,
  },
});

const options = [
  {
    icon: icons.cursorClick,
    color: '#26CFDB',
    title: 'home.stepOneTitle',
    description: 'home.stepOneMessage',
    image: '/static/illustation/lol.jpg',
  },
  {
    icon: icons.annotation,
    color: '#46DB83',
    title: 'home.stepTwoTitle',
    description: 'home.stepTwoMessage',
    image: '/static/illustation/lol.jpg',
  },
  {
    icon: icons.collection,
    color: '#F7D600',
    title: 'home.stepThreeTitle',
    description: 'home.stepThreeMessage',
    image: '/static/illustation/lol.jpg',
  },
  {
    icon: icons.globe,
    color: '#ff7a93',
    title: 'home.stepFourTitle',
    description: 'home.stepFourMessage',
    image: '/static/illustation/lol.jpg',
  },
];

export default function Works() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mb={10}>
      <Container>
        <Grid container spacing={2}>
          {options.map((option, index) => (
            <Grid key={option.title} item xs={12} sm={6} md={3}>
              <Box textAlign="center">
                <Card elevation={0}>
                  <CardContent>
                    <Box
                      mb={1.5}
                      borderRadius={8}
                      display="inline-flex"
                      p={1}
                      style={{
                        color: option.color,
                        backgroundColor: fade(option.color, 0.15),
                      }}
                    >
                      <SvgIcon color="inherit" fontSize="large">
                        {option.icon}
                      </SvgIcon>
                    </Box>
                    <Typography
                      className={classes.title}
                      gutterBottom
                      variant="h5"
                    >
                      {index + 1}. {t(option.title)}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.description}
                      variant="body1"
                    >
                      {t(option.description)}.
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
