import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Button,
  Box,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useLocale from 'hooks/useLocale';
import LanguageSelect from './LanguageSelect';
import useAnalytics from 'hooks/useAnalytics';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff8d9',
    paddingTop: theme.headerHeight,
    paddingBottom: 20,
    '&::after': {
      content: "''",
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      display: 'block',
      bottom: -80,
      width: '100%',
      height: 151,
      backgroundImage: 'url("/static/images/wave.svg")',
    },
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  body: {
    fontSize: '20px',
    lineHeight: '1.7',
  },
  cta: {
    height: '100%',
  },
  heroImage: {
    height: 380,
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  languageChooser: {
    marginBottom: 0,
  },
}));

export default function Hero({
  code,
  language: lang,
}: {
  code?: string;
  language: string;
}) {
  const classes = useStyles();
  const { t, localePath } = useLocale();
  const { track } = useAnalytics();
  const [language, setLanguage] = useState(code || 'es');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box position="relative">
      <div className={classes.root}>
        <Container component="section" className={classes.container}>
          <Grid container spacing={4} alignItems="center">
            <Grid item sm={12} md={6}>
              <Typography variant={mobile ? 'h2' : 'h1'} component="h1">
                {lang
                  ? t('home.titleLanguage', { language: lang })
                  : t('home.title')}
              </Typography>
              <Box maxWidth={600} mt={3} mb={3}>
                <Typography
                  className={classes.body}
                  color="textSecondary"
                  variant="body1"
                >
                  {t('home.learn')}.
                </Typography>
              </Box>
              <Box maxWidth={500}>
                <Grid container spacing={2} className={classes.languageChooser}>
                  <Grid item xs={6}>
                    <LanguageSelect
                      selected={language}
                      onSelect={setLanguage}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      component={RouterLink}
                      size="large"
                      // to={localePath(`/welcome/practice?language=${language}`)}
                      to={localePath('/#pricing')}
                      className={classes.cta}
                      onClick={() =>
                        track('tutorial_begin', { value: language })
                      }
                    >
                      {t('home.hero')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={12} md={6}>
              <img
                className={classes.heroImage}
                src="/static/images/converse.png"
                alt="Video chat conversation"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Box>
  );
}
