import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import icons from 'config/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  inner: {
    padding: theme.spacing(6, 3),
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
  },
  body: {
    fontSize: '20px',
    lineHeight: '1.7',
  },
  media: {
    '& img': {
      width: '90%',
      height: 'auto',
    },
  },
}));

export default function Features() {
  const classes = useStyles();
  const { t } = useTranslation();

  const options = [
    {
      icon: icons.cursorClick,
      color: '#26CFDB',
      title: 'home.workOneTitle',
      message: 'home.workOneMessage',
      image: 'feature-1.png',
    },
    {
      icon: icons.globe,
      color: '#46DB83',
      title: 'home.workTwoTitle',
      message: 'home.workTwoMessage',
      image: 'feature-2.png',
    },
    {
      icon: icons.collection,
      color: '#F7D600',
      title: 'home.workThreeTitle',
      message: 'home.workThreeMessage',
      image: 'feature-3.png',
    },
    // {
    //   icon: icons.annotation,
    //   color: '#ff7a93',
    //   title: 'home.workFourTitle',
    //   message: 'home.workFourMessage',
    //   image: 'feature-4.png',
    // },
  ];

  return (
    <Box id="features" component="section" mb={12}>
      {options.map((option, index) => (
        <div
          key={option.title}
          className={clsx({
            [classes.root]: index % 2 === 0,
          })}
        >
          <div className={classes.inner}>
            <Container>
              <Grid
                alignItems="center"
                container
                justify="space-between"
                direction={index % 2 === 0 ? 'row-reverse' : 'row'}
              >
                <Grid item lg={6} md={6} xs={12}>
                  <Typography gutterBottom variant="h2">
                    {t(option.title)}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.body}
                  >
                    {t(option.message)}.
                  </Typography>
                </Grid>
                <Grid className={classes.media} item lg={6} md={6} xs={12}>
                  <img
                    alt="User flows"
                    src={`/static/images/${option.image}`}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      ))}
    </Box>
  );
}
