import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.text.primary} !important`,
    boxShadow: `${theme.shadows[6]} !important`,
    fontFamily: 'inherit',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  variantSuccess: {
    borderLeft: `6px solid ${theme.palette.success.main}`,
    '& svg': {
      color: theme.palette.success.main,
    },
  },
  variantError: {
    borderLeft: `6px solid ${theme.palette.error.main}`,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  variantInfo: {
    borderLeft: `6px solid ${theme.palette.info.main}`,
    '& svg': {
      color: theme.palette.info.main,
    },
  },
  variantWarning: {
    borderLeft: `6px solid ${theme.palette.warning.main}`,
    '& svg': {
      color: theme.palette.warning.main,
    },
  },
}));

export default function NotistackProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  );
}
