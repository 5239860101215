/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';
import { nanoid } from 'nanoid';
import firebase, { firestore, functions } from 'services/firebase';
import FriendlyError from 'state/FriendlyError';
import {
  FirebaseUser,
  FormikUser,
  NewUser,
  User,
  UserPrivate,
} from 'state/types';
import fileService from 'services/file';
import { auth } from 'services/firebase';

export interface UserContextType {
  createProfile: (
    user: FirebaseUser,
    profile: FormikUser,
    useFormikPhoto?: boolean
  ) => Promise<{ user: FirebaseUser; profile: NewUser } | undefined>;
  getProfile: (uid: string) => Promise<User | null>;
  getProfileByUsername: (usernmae: string) => Promise<User | undefined>;
  getMyProfile: (callback: (user: User | null) => void) => void | (() => void);
  getMyProfilePrivate: (
    callback: (user: UserPrivate | null) => void
  ) => void | (() => void);
  updateProfile: (profile: Partial<User>) => any;
  claimFreeCredits: () => void;
  updateUsername: (username: string) => void;
}

export default function useUser() {
  const createProfile: UserContextType['createProfile'] = async (
    user,
    profile
  ) => {
    try {
      let photoName = '';

      let photoURL = '';

      if (profile.photo) {
        photoName = `${user.uid}-${profile.photo!.name}-${nanoid()}`;
        photoURL = await fileService.uploadFile(profile.photo!, photoName);
      }

      const geoInfo = JSON.parse(localStorage.getItem('geo_info') || '{}');

      const newUser: NewUser = {
        uid: user.uid,
        username: user.uid,
        online: true,
        photoURL: photoURL || '',
        photoName: photoName,
        dob: firebase.firestore.Timestamp.fromDate(
          new Date(
            `${profile.dob.month}-${profile.dob.day}-${profile.dob.year}`
          )
        ),
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        lastActive: firebase.firestore.FieldValue.serverTimestamp() as any,
        lastCallMadeAt: null,
        native: profile.native,
        practice: profile.practice,
        fluent: profile.fluent,
        friendsCount: 0,
        pendingCount: 0,
        blockedCount: 0,
        interests: profile.interests,
        gender: profile.gender,
        firstName: profile.firstName,
        lastName: profile.lastName,
        agree: profile.agree,
        hasNewNotifications: false,
        onboardStep: 1,

        noNotificationLanguagesNative: [],
        noNotificationLanguagesPractice: [],

        countryCode: geoInfo.country_code || 'US',
        timeZone: geoInfo.time_zone || 'America/Los_Angeles',
        currency: geoInfo.currency || 'USD',
        timeFormat: 12,

        notificationSettings: {
          nativeSpeakerOnlineDesktop: true,
          nativeSpeakerOnlineEmail: false,
          learnerSpeakerOnlineDesktop: true,
          learnerSpeakerOnlineEmail: false,
          newFriendDesktop: true,
          newFriendEmail: true,
          notFoundTeacherFoundDesktop: false,
          notFoundTeacherFoundEmail: true,
        },

        hideOnboardBanner: false,
        addedFirstFlashcard: false,
        madeFirstCall: false,
      };

      if (!user.providerData.includes('password') && auth.currentUser) {
        const fullName = profile.firstName + ' ' + profile.lastName;
        if (auth.currentUser.displayName !== fullName) {
          await auth.currentUser.updateProfile({
            displayName: fullName,
          });
        }
      }

      await firestore.collection('users').doc(user.uid).set(newUser);

      return { user, profile: newUser };
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  };

  const getProfile: UserContextType['getProfile'] = useCallback(async function (
    uid
  ) {
    try {
      const doc = firestore.collection('users').doc(uid);
      const user = await doc.get();
      return (user.data() as User) || null;
    } catch (error) {
      throw new FriendlyError((error as any).code);
    }
  },
  []);

  const getProfileByUsername: UserContextType['getProfileByUsername'] = useCallback(
    async function (username) {
      try {
        const users: User[] = [];
        const docs = await firestore
          .collection('users')
          .where('username', '==', username.toLowerCase())
          .get();

        docs.forEach(function (snapshot) {
          users.push(snapshot.data() as User);
        });
        return users[0];
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    },
    []
  );

  const getMyProfile: UserContextType['getMyProfile'] = useCallback(function (
    callback
  ) {
    if (auth.currentUser) {
      try {
        const doc = firestore.collection('users').doc(auth.currentUser.uid);
        return doc.onSnapshot(function (docSnapshot) {
          if (docSnapshot.exists) {
            const user = docSnapshot.data() as User | null;
            callback(user);
          } else {
            callback(null);
          }
        });
      } catch (error) {
        throw new FriendlyError((error as any).code);
      }
    }
  },
  []);

  const getMyProfilePrivate: UserContextType['getMyProfilePrivate'] = useCallback(
    function (callback) {
      if (auth.currentUser) {
        try {
          const doc = firestore
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('private')
            .doc(auth.currentUser.uid);

          return doc.onSnapshot(function (docSnapshot) {
            if (docSnapshot.exists) {
              const user = docSnapshot.data() as UserPrivate | null;
              callback(user);
            } else {
              callback(null);
            }
          });
        } catch (error) {
          throw new FriendlyError((error as any).code);
        }
      }
    },
    []
  );

  const updateProfile: UserContextType['updateProfile'] = useCallback(
    async (options) => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        try {
          await firestore.collection('users').doc(uid).update(options);
        } catch (error) {
          throw new FriendlyError((error as any).code);
        }
      }
    },
    []
  );

  const claimFreeCredits: UserContextType['claimFreeCredits'] = useCallback(async () => {
    try {
      const claimFreeCredits = functions.httpsCallable('claimFreeCredits');
      await claimFreeCredits();
    } catch (error) {
      throw new FriendlyError((error as any).message);
    }
  }, []);

  const updateUsername: UserContextType['updateUsername'] = useCallback(
    async (username) => {
      try {
        const updateUsername = functions.httpsCallable('updateUsername');
        await updateUsername({ username });
      } catch (error) {
        throw new FriendlyError((error as any).message);
      }
    },
    []
  );

  return {
    getProfile,
    createProfile,
    getMyProfile,
    getMyProfilePrivate,
    getProfileByUsername,
    updateProfile,
    claimFreeCredits,
    updateUsername,
  };
}
