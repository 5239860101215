import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { colors as mui } from '@material-ui/core';
import color from 'config/palette';

const palette: PaletteOptions = {
  common: {
    black: color.grey600,
    white: mui.common.white,
  },
  primary: {
    light: color.indigo300,
    main: color.indigo400,
    dark: color.indigo500,
  },
  secondary: {
    light: color.green300,
    main: color.green400,
    dark: color.green500,
  },
  error: {
    main: '#e95432',
  },
  success: {
    main: '#36b37e',
  },
  warning: {
    main: '#faad13',
  },
  info: {
    main: '#00b8d9',
  },
  text: {
    primary: color.grey500,
    secondary: color.grey450,
    disabled: color.grey400,
    hint: color.grey400,
  },
  divider: color.grey200,
  background: {
    paper: mui.common.white,
    default: color.grey100,
  },
  action: {
    active: color.grey350,
    hover: color.grey100,
    selected: color.grey150,
    focus: color.grey150,
    disabled: color.grey400,
    disabledBackground: color.grey150,
  },
};

export default palette;
