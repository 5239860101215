import { fade, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28 * 1.5,
    height: 16 * 1.5,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: fade(theme.palette.action.disabled, 0.5),
    '&$checked': {
      transform: 'translateX(18px) !important',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        backgroundImage:
          'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12 * 1.5,
    height: 12 * 1.5,
    boxShadow: 'none',
  },
  track: {
    borderRadius: (16 * 1.5) / 2,
    opacity: 1,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
  },
  checked: {},
}))(Switch);

export default AntSwitch;
