import isUndefined from 'lodash.isundefined';
import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  makeStyles,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useSelected from 'hooks/useSelected';
import useLocale from 'hooks/useLocale';

interface Route {
  name: string;
  to: string;
  badge?: number;
}

interface NavProps {
  routes: Route[];
}

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        color: theme.palette.primary.main,
      },
    },
  },
  gutters: {
    paddingLeft: 0,
  },
}));

export default function Navbar({ routes }: NavProps) {
  const selected = useSelected();
  const classes = useStyles();
  const { localePath } = useLocale();

  const renderBadge = (route: Route) => {
    if (isUndefined(route.badge)) {
      return null;
    }

    return (
      <ListItemSecondaryAction>
        <Badge badgeContent={String(route.badge)} />
        {/* <Badge badgeContent={String(route.badge)} color="secondary" /> */}
      </ListItemSecondaryAction>
    );
  };

  return (
    <List>
      {routes.map((route) => (
        <ListItem
          button
          to={localePath(route.to)}
          key={localePath(route.to)}
          component={RouterLink}
          classes={{ button: classes.button, gutters: classes.gutters }}
        >
          <ListItemText
            primary={route.name}
            primaryTypographyProps={{
              variant: 'body1',
              color: selected(localePath(route.to), true)
                ? 'primary'
                : 'textPrimary',
            }}
          />
          {renderBadge(route)}
        </ListItem>
      ))}
    </List>
  );
}
