import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  body: {
    fontSize: '20px',
    lineHeight: '1.7',
  },
}));

export default function Testimonials() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography gutterBottom variant="h3" align="center">
          &quot;{t('home.testimonial')}.&quot;
        </Typography>
        <Box mt={6} display="flex" justifyContent="center" alignItems="center">
          <Avatar
            className={classes.avatar}
            src="/static/images/testimonial.jpeg"
          />
          <Box ml={2}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.body}
            >
              {t('home.testimonialName')}
              <Typography
                color="textSecondary"
                display="inline"
                component="span"
                className={classes.body}
              >
                , {t('home.testimonialStudent')}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
