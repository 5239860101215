import { useAppState } from 'state';
import { useCallback } from 'react';

export default function useLanguage() {
  const { languages } = useAppState();

  const languageCodeToCountryCode = useCallback(
    (code: string) => {
      if (code && languages[code]) {
        return languages[code].countryCode;
      }
      return '';
    },
    [languages]
  );

  const languageCodeToName = useCallback(
    (code: string) => {
      if (code && languages[code]) {
        return languages[code].name;
      }
      return '';
    },
    [languages]
  );

  const languageCodeToNative = useCallback(
    (code: string) => {
      if (code && languages[code]) {
        return languages[code].native;
      }
      return '';
    },
    [languages]
  );

  const languageCodeToNameNative = useCallback(
    (code: string) => {
      if (code && languages[code]) {
        const native = languageCodeToNative(code);
        const nativeName = native ? ' (' + native + ')' : '';

        return languageCodeToName(code) + nativeName;
      }
      return '';
    },
    [languageCodeToName, languageCodeToNative, languages]
  );

  const languageCodeToFlag = useCallback(
    (code: string) => {
      const country = languageCodeToCountryCode(code);
      const countryCode = country.toLocaleLowerCase();

      if (countryCode) {
        return `/static/flags/${countryCode}.svg`;
      }

      return '';
    },
    [languageCodeToCountryCode]
  );

  return {
    languageCodeToCountryCode,
    languageCodeToName,
    languageCodeToNative,
    languageCodeToNameNative,
    languageCodeToFlag,
  };
}
