import { ComponentsProps } from '@material-ui/core/styles/props';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import { Fade } from '@material-ui/core';

const props: ComponentsProps = {
  MuiTypography: {
    variant: 'body2',
  },
  MuiAppBar: {
    position: 'static',
    color: 'inherit',
  },
  MuiCard: {
    elevation: 3,
  },
  MuiTextField: {
    variant: 'outlined',
    margin: 'dense',
    fullWidth: true,
    InputProps: {
      notched: false,
    },
  },
  MuiButton: {
    disableElevation: true,
    size: 'medium',
    color: 'primary',
    variant: 'contained',
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiIconButton: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  MuiSelect: {
    fullWidth: true,
    margin: 'dense',
    variant: 'outlined',
    IconComponent: KeyboardArrowDownRounded,
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      TransitionComponent: Fade,
    },
  },
  MuiInputLabel: {
    shrink: true,
    variant: 'standard',
  },
  MuiLink: {
    variant: 'body2',
    color: 'primary',
    underline: 'hover',
  },
  MuiCircularProgress: {
    size: 18,
    color: 'inherit',
  },
  MuiPopover: {
    TransitionComponent: Fade,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  MuiMenu: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  },
  MuiMenuItem: {
    dense: true,
  },
  MuiListItemText: {
    primaryTypographyProps: {
      variant: 'body2',
    },
  },
  MuiTooltip: {
    TransitionComponent: Fade,
    placement: 'top',
    arrow: true,
  },
  MuiDialog: {
    scroll: 'body',
    maxWidth: 'sm',
    fullWidth: true,
    keepMounted: true,
  },
  MuiDialogTitle: {
    disableTypography: true,
  },
  MuiDialogContent: {
    dividers: true,
  },
  MuiSwitch: {
    size: 'small',
  },
  MuiCardHeader: {
    titleTypographyProps: {
      variant: 'h5',
    },
    subheaderTypographyProps: {
      variant: 'body2',
    },
  },
};

export default props;
