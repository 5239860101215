import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Button,
  fade,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'visible',
    backgroundColor: fade(theme.palette.primary.light, 0.15),
  },
  cover: {
    height: '75%',
    marginTop: '13%',
    backgroundSize: 'contain',
  },
}));

interface ProPackageProps {
  credits: string;
  onSelect: () => void;
}

export default function ProPackage({ credits, onSelect }: ProPackageProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={0}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <CardContent>
            <Typography color="primary" variant="h5">
              {t('credits.pro')}
            </Typography>
            <Box maxWidth={200} pt={0.5} pb={1.5}>
              <Typography>{t('credits.proDetails', { credits })}</Typography>
            </Box>
            <Button onClick={onSelect} size="small">
              {t('credits.proTry')}
            </Button>
          </CardContent>
        </Grid>
        <Grid item xs={4}>
          <CardMedia
            className={classes.cover}
            image="/static/illustrations/Update.png"
            title={t('credits.pro')}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
