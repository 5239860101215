import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { Box, makeStyles } from '@material-ui/core';
import { useParams, Redirect } from 'react-router';
import Meta from 'components/Meta';
import { FacebookCircularProgress } from 'components/SplashScreen';
import useLocale from 'hooks/useLocale';
import { useAppState } from 'state';
import Hero from './Hero';
import Why from './Why';
import Features from './Features';
import Testimonial from './Testimonial';
import Dashboard from './Dashboard';
import Works from './Works';
import Chat from './Chat';
import Pricing from './Pricing';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

export default function Home() {
  const classes = useStyles();
  const { languages } = useAppState();
  const { localePath } = useLocale();
  const { lang } = useParams<{ lang?: string }>();
  const [loading, setLoading] = useState(!!lang);
  const [language, setLanguage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [invalidLang, setInvalidLang] = useState(false);

  useEffect(
    function () {
      if (lang && !isEmpty(languages)) {
        const selectedLanguage = languages[lang];
        if (selectedLanguage && selectedLanguage.name) {
          setLanguage(selectedLanguage.name);
          setCode(selectedLanguage.code);
          setLoading(false);
        } else {
          setLoading(false);
          setInvalidLang(true);
        }
      }
    },
    [languages, lang]
  );

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh"
      >
        <FacebookCircularProgress size={30} />
      </Box>
    );
  }

  if (invalidLang) {
    return <Redirect to={localePath('/')} />;
  }

  return (
    <div id="home" className={classes.root}>
      <Meta />
      <Hero code={code} language={language} />
      <Why />
      <Works />
      <Chat />
      <Features />
      <Testimonial />
      <Pricing />
      <Dashboard />
    </div>
  );
}
