import React, { useState, useCallback, useEffect } from 'react';
import {
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Box,
  makeStyles,
  DialogContent,
  fade,
} from '@material-ui/core';
// import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import Alert from 'components/Alert';
import { useAppState } from 'state';
import { onServerError } from 'utils/helpers';
import Dialog from 'components/Dialog';
import useUser from 'state/firestore/useUser';
import TeachNotification from 'components/Notification/TeachNotification';
import useLanguage from 'hooks/useLanguage';
import Packages from 'components/Credits/Packages';
import Checkout from 'components/Credits/Checkout';

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
  },
  paper: {
    overflow: 'hidden',
  },
  image: {
    width: 120,
    height: 120,
    backgroundImage: 'url("/static/illustrations/Congratulations.png")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    margin: '0 auto',
    backgroundRepeat: 'no-repeat',
  },
  image2: {
    width: 120,
    height: 120,
    backgroundImage: 'url("/static/illustrations/Update.png")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    margin: '0 auto',
    backgroundRepeat: 'no-repeat',
  },
  iconContainer: {
    width: 40,
    height: 40,
    border: '1px solid',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    backgroundColor: fade(theme.palette.secondary.light, 0.3),
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(1.5),
  },
  title: {
    paddingBottom: 0,
  },
  comingSoon: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    fontSize: 11,
    padding: '1px 5px',
    marginTop: 2,
    display: 'inline-flex',
  },
  header1: {
    backgroundColor: '#ef6b68',
    padding: theme.spacing(2),
  },
  header2: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(4),
  },
  headTitle: {
    color: theme.palette.common.white,
  },
}));

export default function WelcomeModal() {
  const { profile, packages, profilePrivate } = useAppState();
  const { updateProfile } = useUser();
  const classes = useStyles();
  const { t } = useTranslation();
  const { languageCodeToName } = useLanguage();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (profilePrivate && profilePrivate.credits > 0) {
      setStep(3);
    }
  }, [packages, profilePrivate]);

  useEffect(
    function () {
      if (profile && profile.onboardStep && !step) {
        setStep(profile.onboardStep);
      }
    },
    [step, profile]
  );

  const handleConfirm = useCallback(
    async function () {
      if (profile) {
        try {
          setConfirmLoading(true);
          await updateProfile({
            onboardStep: 0,
          });
        } catch (error) {
          setError(t(onServerError(error)));
        } finally {
          setConfirmLoading(false);
        }
      }
    },
    [profile, updateProfile, t]
  );

  if (!profile || !profile.onboardStep) {
    return null;
  }

  const native = languageCodeToName(Object.keys(profile.native)[0]);
  const practice = languageCodeToName(Object.keys(profile.practice)[0]);

  const step1 = (
    <>
      <div className={classes.header1}>
        <div className={classes.image} />
      </div>
      <DialogContent>
        {/* <Confetti numberOfPieces={500} recycle={false} /> */}
        {error && (
          <Box mx={2} mt={2}>
            <Alert severity="error">{error}.</Alert>
          </Box>
        )}
        <DialogTitle className={classes.title}>
          <Typography align="center" variant="h4" gutterBottom>
            {t('welcomeModal.title')}
            <span aria-label="party" role="img">
              &nbsp;&nbsp;🎉
            </span>
          </Typography>
          <Typography align="center" variant="body2">
            {t('welcomeModal.happy', { native, practice })}.
          </Typography>
        </DialogTitle>
        <DialogActions className={classes.actions}>
          <Button onClick={() => setStep(2)}>{t('general.continue')}</Button>
        </DialogActions>
      </DialogContent>
    </>
  );

  const step2 = (
    <Box>
      <div className={classes.header2}>
        <Typography
          className={classes.headTitle}
          align="center"
          variant="h4"
          gutterBottom
        >
          {t('Get credits to get started')}
        </Typography>
        {/* <Typography
          className={classes.headTitle}
          align="center"
          variant="h6"
          color="textSecondary"
        >
          ${pro ? '9.99/month' : '9.99 one time payment'}
        </Typography> */}
      </div>
      <DialogContent>
        <Box textAlign="center" mt={2}>
          <Checkout onSuccess={() => setStep(3)} />
          <Packages simplePaidPlan />
        </Box>
      </DialogContent>
    </Box>
  );

  const step3 = (
    <Box>
      <div className={classes.header2}>
        <TeachNotification
          disabled={true}
          onAccept={() => null}
          onDeny={() => null}
          language={native}
          credits={20}
          type="native"
          id={profile.uid}
        />
      </div>
      <DialogContent>
        <DialogTitle className={classes.title}>
          <Typography align="center" variant="h4" gutterBottom>
            {t('welcomeModal.title2')}
          </Typography>
          <Typography align="center" variant="body2">
            {t('welcomeModal.message2', { native, practice })}.
          </Typography>
        </DialogTitle>
        <Box textAlign="center" mt={2}>
          <Button
            onClick={handleConfirm}
            disabled={confirmLoading}
            size="medium"
            startIcon={confirmLoading && <CircularProgress />}
          >
            {t('welcomeModal.start')}
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );

  return (
    <Dialog
      open={profile.onboardStep > 0}
      disableBackdropClick
      classes={{ paper: classes.paper }}
    >
      {step === 1 && step1}
      {step === 2 && step2}
      {step === 3 && step3}
    </Dialog>
  );
}
