/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  makeStyles,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/Dialog';
import { useAppState } from 'state';
import { packageToGA, toPrice } from 'utils/helpers';
import fetchFromAPI from 'services/fetchFromApi';
import useAnalytics from 'hooks/useAnalytics';
import FormikRadio from 'formik/FormikRadio';

import PaymentForm from './PaymentForm';
import Success from './Success';
import Paypal from './Paypal';

export const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'space-between',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 2,
    color: theme.palette.text.secondary,
  },
}));

export default function Checkout({ onSuccess }: { onSuccess?: () => void }) {
  const classes = useStyles();
  const {
    profile,
    selectedPackage,
    setSelectedPackage,
    setProfilePrivate,
  } = useAppState();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const { track, EventName } = useAnalytics();

  const [clientSecret, setClientSecret] = useState('');
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [success, setSuccess] = useState(false);

  const initialValues = {
    method: 'card',
    firstName: '',
    lastName: '',
    email: '',
  };

  const PurchaseSchema = Yup.object().shape({
    method: Yup.string(),
    firstName: Yup.string().required('validation.firstNameRequired'),
    lastName: Yup.string().required('validation.lastNameRequired'),
    email: Yup.string()
      .email('validation.emailInvalid')
      .required('validation.emailRequired'),
  });

  useEffect(
    function () {
      const createPayment = async function () {
        if (selectedPackage) {
          if (selectedPackage.description === 'pro') {
            return null;
          }

          const { clientSecret } = await fetchFromAPI(
            'stripe/create-payment-intent',
            {
              data: {
                productId: selectedPackage.id,
                priceId: selectedPackage.price_id,
              },
            }
          );
          setClientSecret(clientSecret);
        }
      };

      if (!clientSecret) {
        createPayment();
      }
    },
    [clientSecret, selectedPackage]
  );

  const handleClose = useCallback(
    function () {
      if (selectedPackage) {
        track(EventName.REMOVE_FROM_CART, {
          currency: 'USD',
          value: selectedPackage.unit_amount / 100,
          items: [packageToGA(selectedPackage)],
        });
      }
      setSelectedPackage(null);
    },
    [setSelectedPackage, track, EventName, selectedPackage]
  );

  const generateStripeLocaleError = (code: string | undefined) => {
    switch (code) {
      case 'card_declined':
        return 'stripeCardDeclineError';
      default:
        return 'stripeAuthFailureError';
    }
  };

  const handlePaymentThatRequiresCustomerAction = useCallback(
    async ({
      paymentMethodId,
      subscription,
      invoice,
      isRetry,
    }: {
      paymentMethodId: string | undefined;
      subscription?: any;
      invoice?: any;
      isRetry?: boolean;
    }) => {
      if (!selectedPackage) {
        return null;
      }

      if (subscription && subscription.status === 'active') {
        return {
          subscription,
          priceId: selectedPackage.price_id,
          paymentMethodId,
        };
      }

      if (!stripe) {
        return null;
      }

      const paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent;

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        const result = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId,
          }
        );

        if (result.error) {
          throw result;
        }

        if (result.paymentIntent?.status === 'succeeded') {
          return {
            priceId: selectedPackage.price_id,
            subscription: subscription,
            invoice: invoice,
            paymentMethodId: paymentMethodId,
          };
        }

        // No customer action needed
        return {
          subscription,
          priceId: selectedPackage.price_id,
          paymentMethodId,
        };
      }
    },
    [selectedPackage, stripe]
  );

  const onSubscriptionComplete = useCallback(
    (result: any) => {
      if (!profile || !selectedPackage) {
        return null;
      }

      if (result && !result.subscription) {
        const subscription = { id: result.invoice.subscription };
        result.subscription = subscription;
        localStorage.clear();
      }

      setSuccess(true);
      setProfilePrivate((profilePrivate) => ({
        ...profilePrivate,
        credits: parseFloat(selectedPackage.metadata.credits),
      }));
    },
    [setSuccess, setProfilePrivate, profile, selectedPackage]
  );

  const handleRequiresPaymentMethod = useCallback(
    (subscription: any, paymentMethodId: string | undefined) => {
      if (!selectedPackage) {
        return null;
      }

      if (subscription.status === 'active') {
        return {
          subscription,
          priceId: selectedPackage.price_id,
          paymentMethodId,
        };
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem(
          'latestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status
        );
        throw new Error('Your card was declined.');
      } else {
        return {
          subscription,
          priceId: selectedPackage.price_id,
          paymentMethodId,
        };
      }
    },
    [selectedPackage]
  );

  const createSubscription = useCallback(
    async (paymentMethodId: string | undefined) => {
      if (!selectedPackage) {
        return null;
      }

      const result = await fetchFromAPI('stripe/create-subscription', {
        data: {
          paymentMethodId: paymentMethodId,
          priceId: selectedPackage.price_id,
        },
      });

      const subscription = await handlePaymentThatRequiresCustomerAction({
        subscription: result,
        paymentMethodId: paymentMethodId,
      });

      await handleRequiresPaymentMethod(
        subscription?.subscription,
        subscription?.paymentMethodId
      );

      onSubscriptionComplete(subscription);
    },
    [
      handlePaymentThatRequiresCustomerAction,
      handleRequiresPaymentMethod,
      onSubscriptionComplete,
      selectedPackage,
    ]
  );

  const retryInvoiceWithNewPaymentMethod = useCallback(
    async (
      paymentMethodId: string | undefined,
      invoiceId: string | null,
      setError: (error: any) => void
    ) => {
      try {
        const result = await fetchFromAPI('stripe/retry-invoice', {
          data: {
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
          },
        });

        const subscription = await handlePaymentThatRequiresCustomerAction({
          invoice: result,
          paymentMethodId: paymentMethodId,
          isRetry: true,
        });

        onSubscriptionComplete(subscription);
      } catch (error) {
        setProcessing(false);
        setError((error as any).code);
      }
    },
    [handlePaymentThatRequiresCustomerAction, onSubscriptionComplete]
  );

  const onSubmit = useCallback(
    async function (values, actions) {
      if (!stripe || !elements || !selectedPackage) {
        return null;
      }

      const setError = (code = '') => {
        setProcessing(false);
        return actions.setStatus({
          checkoutError: t(`credits.${generateStripeLocaleError(code)}`),
        });
      };

      const cardElement = elements.getElement(CardElement);

      setProcessing(true);

      try {
        if (selectedPackage.description !== 'pro') {
          const payload = await stripe.confirmCardPayment(clientSecret, {
            receipt_email: values.email,
            payment_method: {
              card: cardElement!,
              billing_details: {
                name: `${values.firstName} ${values.lastName}`,
                email: values.email,
              },
            },
          });

          if (payload.error) {
            return setError(payload.error.code);
          }
        } else {
          const latestInvoicePaymentIntentStatus = localStorage.getItem(
            'latestInvoicePaymentIntentStatus'
          );

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
            billing_details: {
              name: `${values.firstName} ${values.lastName}`,
              email: values.email,
            },
          });

          if (error) {
            return setError(error.code);
          }

          if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
            const invoiceId = localStorage.getItem('latestInvoiceId');
            await retryInvoiceWithNewPaymentMethod(
              paymentMethod?.id,
              invoiceId,
              setError
            );
            return;
          }
          await createSubscription(paymentMethod?.id);
        }

        setProcessing(false);

        if (profile) {
          setProfilePrivate((profilePrivate) => ({
            ...profilePrivate,
            credits: parseFloat(selectedPackage.metadata.credits),
          }));
        }

        track(EventName.PURCHASE, {
          currency: 'USD',
          value: selectedPackage.unit_amount / 100,
          items: [packageToGA(selectedPackage)],
        });

        setSuccess(true);

        if (onSuccess) onSuccess();
      } catch (error) {
        actions.setStatus({
          checkoutError: t('credits.checkoutError'),
        });
      } finally {
        setProcessing(false);
      }
    },
    [
      clientSecret,
      elements,
      stripe,
      t,
      profile,
      selectedPackage,
      setProfilePrivate,
      track,
      EventName,
      createSubscription,
      retryInvoiceWithNewPaymentMethod,
      onSuccess,
    ]
  );

  const paypalOnError = useCallback(
    function () {
      enqueueSnackbar(t('credits.paypalError'), {
        variant: 'error',
        persist: true,
      });
    },
    [t, enqueueSnackbar]
  );

  const paypalOnSuccess = useCallback(
    async function (productId: string) {
      try {
        await fetchFromAPI('paypal/add-credits', {
          data: {
            productId,
          },
        });
        if (selectedPackage) {
          track(EventName.PURCHASE, {
            currency: 'USD',
            value: selectedPackage.unit_amount / 100,
            items: [packageToGA(selectedPackage)],
          });
        }
        setSuccess(true);
      } catch (error) {
        paypalOnError();
      }
    },
    [setSuccess, paypalOnError, track, EventName, selectedPackage]
  );

  if (!profile || !selectedPackage) {
    return null;
  }

  const proPackage = selectedPackage.description === 'pro';

  function renderTitle() {
    if (!selectedPackage) {
      return null;
    }

    if (proPackage) {
      return (
        <>
          <img width={20} src="/static/icons/pro.svg" alt="pro-badge" />
          <Box pl={1}>{t('credits.purchasePro')}</Box>
        </>
      );
    }

    return (
      <>
        <img width={20} src="/static/icons/coin.svg" alt="coin" />
        <Box pl={1}>
          {t('credits.purchase', {
            credits: selectedPackage.metadata.credits,
          })}
        </Box>
      </>
    );
  }

  const options = ['card', 'paypal'];

  return (
    <Dialog open={!!selectedPackage}>
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={PurchaseSchema}
        onSubmit={onSubmit}
      >
        {(formikBag) => (
          <Form>
            {success && <Success onClose={() => setSuccess(false)} />}
            {!success && (
              <>
                <DialogTitle>
                  <Typography variant="h6">
                    <Box display="flex" alignItems="center">
                      {renderTitle()}
                    </Box>
                  </Typography>
                  <IconButton className={classes.close} onClick={handleClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  {!proPackage && (
                    <Box mb={1}>
                      <FormikRadio
                        row
                        name="method"
                        options={options.map((option) => ({
                          label: t('credits.' + option),
                          value: option,
                        }))}
                      />
                    </Box>
                  )}
                  {formikBag.values.method === 'card' && (
                    <PaymentForm
                      setDisabled={setDisabled}
                      setStatus={formikBag.setStatus}
                    />
                  )}
                  {formikBag.values.method === 'paypal' && (
                    <Box maxWidth={250} margin="0 auto">
                      <Paypal
                        selectedPackage={selectedPackage}
                        onSuccess={paypalOnSuccess}
                        onError={paypalOnError}
                      />
                    </Box>
                  )}
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                  <Typography>
                    {t('credits.totalAmount', {
                      amount: toPrice(selectedPackage.unit_amount),
                    })}
                    {proPackage && <span>/{t('credits.monthly')}</span>}
                  </Typography>
                  {formikBag.values.method === 'card' && (
                    <Button
                      type="submit"
                      startIcon={processing && <CircularProgress />}
                      disabled={
                        processing ||
                        disabled ||
                        !(formikBag.isValid && formikBag.dirty)
                      }
                    >
                      {t('credits.completePurchase')}
                    </Button>
                  )}
                </DialogActions>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
