import React from 'react';
import {
  Box,
  Avatar as MUAvatar,
  AvatarProps as MUAvatarProps,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

interface AvatarProps extends MUAvatarProps {
  size?: number;
  online?: boolean;
}

const useStyles = makeStyles((theme) => ({
  indicator: {
    position: 'absolute',
    right: 0,
    bottom: -4,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.divider,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    borderRadius: '50%',
    padding: theme.spacing(0.1),
  },
  online: {
    backgroundColor: theme.palette.success.main,
  },
}));

export default function Avatar({ size = 35, online, ...rest }: AvatarProps) {
  const classes = useStyles();
  return (
    <Box position="relative">
      <MUAvatar
        style={{ width: size, height: size, fontSize: size / 2.2 }}
        {...rest}
      />
      <div
        style={{ width: size / 3, height: size / 3 }}
        className={clsx(classes.indicator, {
          [classes.online]: online,
        })}
      />
    </Box>
  );
}
