import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: [
    'DM Sans',
    '-apple-system',
    'Segoe UI',
    'Roboto',
    'Oxygen-Sans',
    'Ubuntu',
    'Cantarell',
    'Helvetica',
    'Neue',
    'sans-serif',
  ].join(','),
  h1: {
    fontWeight: 700,
    fontSize: '60px',
    letterSpacing: '-.5px',
    lineHeight: '66px',
  },
  h2: {
    fontWeight: 700,
    fontSize: '46px',
    letterSpacing: '-0.24px',
    lineHeight: '52.9px',
  },
  h3: {
    fontWeight: 700,
    fontSize: '32px',
    letterSpacing: '-0.06px',
    lineHeight: '38px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '30px',
  },
  h5: {
    fontWeight: 700,
    fontSize: '20px',
    letterSpacing: '-0.05px',
    lineHeight: '30px',
  },
  h6: {
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '18.4px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '16.1px',
  },
  body1: {
    fontSize: '16px',
    lineHeight: '18.4px',
  },
  body2: {
    fontSize: '14px',
    lineHeight: 1.42857,
  },
  button: {
    fontWeight: 500,
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  caption: {
    fontSize: '13px',
    letterSpacing: '0.33px',
    lineHeight: '14.95px',
  },
  overline: {
    fontSize: '14px',
    letterSpacing: '0.33px',
    lineHeight: '21px',
    textTransform: 'uppercase',
  },
};

export default typography;
