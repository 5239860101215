import React from 'react';
import { Helmet, LinkProps, MetaProps } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from 'i18n';
import useLocale from 'hooks/useLocale';
import config from 'config';

interface MetaTagsProps {
  title?: string;
  description?: string;
  url?: string;
  keywords?: string;
  locale?: string;
  image?: string;
  imageAlt?: string;
  author?: string;
  jsonld?: string;
}

export default function Meta(props: MetaTagsProps) {
  const { t, localePath } = useLocale();
  const { i18n } = useTranslation();

  const website = 'https://lingoduck.com';

  const {
    title: metaTitle = t('meta.title'),
    description = t('meta.description'),
    // keywords = 'sign language, language translator, chinese language, language exchange, online language learning, learn languages, language courses, learn french online, learn german online, learn spanish online, learn swedish online, learn portuguese online, grammar courses, pronunciation training',
    url: metaUrl = '',
    locale = i18n.language,
    image = website + '/static/images/og.jpg',
    imageAlt = t('meta.imageAlt'),
    author = '',
    jsonld = `
      {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "LingoDuck",
        "url": "${website}",
        "logo": "${website}/logo512.png",
        "image": "${website}/logo512.png"
      }
  `,
  } = props;

  const metaArray: MetaProps[] = [];
  const linkArray: LinkProps[] = [];

  const title = `LingoDuck - ${metaTitle}`;

  const url = website + localePath(metaUrl);

  metaArray.push({
    name: 'robots',
    content: 'index, follow',
  });

  metaArray.push({
    property: 'og:type',
    content: 'website',
  });

  metaArray.push({
    name: 'twitter:type',
    content: 'summary',
  });

  // general
  if (title) {
    metaArray.push({
      property: 'og:title',
      content: title,
    });

    metaArray.push({
      name: 'twitter:title',
      content: title,
    });
  }

  if (description) {
    metaArray.push({
      property: 'og:description',
      content: description,
    });

    metaArray.push({
      name: 'twitter:description',
      content: description,
    });
  }

  if (url) {
    linkArray.push({
      rel: 'canonical',
      href: url,
    });

    metaArray.push({
      property: 'og:url',
      content: url,
    });
  }

  if (image) {
    metaArray.push({
      property: 'og:image',
      content: image,
    });

    metaArray.push({
      name: 'twitter:image',
      content: image,
    });

    linkArray.push({
      rel: 'image_src',
      href: image,
    });
  }

  if (imageAlt) {
    metaArray.push({
      property: 'og:image:alt',
      content: imageAlt,
    });

    metaArray.push({
      name: 'twitter:image:alt',
      content: imageAlt,
    });
  }

  if (locale) {
    metaArray.push({
      property: 'og:locale',
      content: locale,
    });
  }

  if (author) {
    metaArray.push({
      name: 'twitter:creator',
      content: author,
    });
  }

  // Languages
  linkArray.push({
    ref: 'alternate',
    href: website,
    hrefLang: 'x-default',
  });

  linkArray.push({
    ref: 'alternate',
    href: website,
    hrefLang: 'en',
  });

  supportedLanguages.forEach((lang) => {
    linkArray.push({
      ref: 'alternate',
      href: website + '/' + lang,
      hrefLang: lang,
    });
  });

  if (config.isDevelopment) {
    return null;
  }

  return (
    <Helmet defer={false} title={title} meta={metaArray} link={linkArray}>
      <html lang={i18n.language} />
      <script type="application/ld+json">{jsonld}</script>
    </Helmet>
  );
}
