import { Shadows } from '@material-ui/core/styles/shadows';

const shadows: Shadows = [
  'none',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',
  '0 1px 3px 0 rgba(45,52,69,.15), 0 0 1px 1px rgba(45,52,69,.05)',

  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',
  '0 1px 4px 1px rgba(45,52,69,0.15), 0 0 2px 2px rgba(45,52,69,0.05)',

  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
  '0 5px 14px rgba(15,31,41,.1), 0 0 3px rgba(15,31,41,.06)',
];

export default shadows;
