import { useReducer, useState, useCallback } from 'react';
import { TwilioError } from 'twilio-video';
import useActiveSinkId from 'views/call/hooks/useActiveSinkId';
import { RoomType } from 'views/call/types';
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction,
} from './twilio/settings/settingsReducer';
import fetchFromAPI from 'services/fetchFromApi';

export interface TwilioContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
}

export default function useTwilio() {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );

  const getToken: TwilioContextType['getToken'] = useCallback((name, room) => {
    const request = fetchFromAPI('twilio/generateToken', {
      data: {
        identity: name,
        room,
      },
    });

    setIsFetching(true);
    return request
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  }, []);

  return {
    error,
    setError,
    isFetching,
    getToken,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  };
}
