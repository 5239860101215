import { storage } from 'services/firebase';
import FriendlyError from 'state/FriendlyError';

class FileService {
  public async uploadFile(file: File, name?: string) {
    const metadata = { contentType: 'image/jpeg' };
    const storageFile = storage.ref().child(`images/${name || file.name}`);
    try {
      const result = await storageFile.put(file, metadata);
      const downloadUrl = await result.ref.getDownloadURL();
      return downloadUrl;
    } catch (error) {
      throw new FriendlyError((error as any).code, (error as any).message);
    }
  }

  public async deleteFile(name: string) {
    const storageFile = storage.ref().child(`images/${name}`);
    try {
      await storageFile.delete();
    } catch (error) {
      throw new FriendlyError((error as any).code, (error as any).message);
    }
  }
}

export default new FileService();
