import React from 'react';
import {
  AppBar,
  Toolbar as MUToolbar,
  Box,
  Container,
  styled,
  Hidden,
  Link,
  makeStyles,
  Button,
  IconButton,
  Badge,
  SvgIcon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuRounded';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'components/Logo';
import useLocale from 'hooks/useLocale';
import icons from 'config/icons';
import Account from './Account';
import Credits from './Credits';
import { useAppState } from 'state';

const Toolbar = styled(MUToolbar)({
  minHeight: 60,
});

interface Route {
  name: string;
  to: string;
  badge?: boolean;
}

interface TopBarProps {
  routes: Route[];
  secondaryRoutes: Route[];
  onMobileNavOpen: () => void;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: theme.headerHeight,
  },
  link: {
    borderRadius: 10,
    marginRight: theme.spacing(2),
  },
  button: {
    minWidth: 38,
    padding: '6px 6px',
    marginLeft: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
  },
}));

export function routeIcon(route: string) {
  switch (route) {
    case '/app/friends':
      return icons.users;
    case '/app/vocabulary':
      return icons.collection;
    case '/app/translate':
      return icons.translate;
    case '/app/referral':
      return icons.gift;
    case '/app/notifications':
      return icons.bell;
    case '/app/profile':
      return icons.userCircle;
    default:
      return icons.home;
  }
}

export default function TopBar({
  onMobileNavOpen,
  routes,
  secondaryRoutes,
}: TopBarProps) {
  const location = useLocation();
  const classes = useStyles();
  const { profilePrivate } = useAppState();
  const { t, localePath } = useLocale();

  return (
    <AppBar position="relative">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            className={classes.logoContainer}
            component={RouterLink}
            to={localePath('/app')}
          >
            <Hidden smDown>
              <Logo width={120} />
            </Hidden>
            <Hidden mdUp>
              <Logo src="/logo-square.svg" width="auto" height={25} />
            </Hidden>
          </Link>
          <Hidden mdUp>
            <IconButton onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Box pl={2} />
            {routes.map((route) => {
              const selected = matchPath(location.pathname, {
                path: localePath(route.to),
              });

              return (
                <Button
                  key={route.name}
                  variant={selected ? 'contained' : 'text'}
                  color="default"
                  className={classes.link}
                  component={RouterLink}
                  to={localePath(route.to)}
                  startIcon={
                    <Badge
                      variant={route.badge ? 'dot' : 'standard'}
                      color="error"
                    >
                      <SvgIcon color={selected ? 'primary' : 'action'}>
                        {routeIcon(route.to)}
                      </SvgIcon>
                    </Badge>
                  }
                >
                  {t(route.name)}
                </Button>
              );
            })}
          </Hidden>
          <Box flexGrow={1} />
          {profilePrivate.credits > 0 && <Credits />}
          {secondaryRoutes.map((route) => {
            const selected = matchPath(location.pathname, {
              path: localePath(route.to),
            });
            return (
              <Button
                key={route.name}
                variant={selected ? 'contained' : 'text'}
                color="default"
                className={classes.button}
                component={RouterLink}
                to={localePath(route.to)}
              >
                <Badge variant={route.badge ? 'dot' : 'standard'} color="error">
                  <SvgIcon color={selected ? 'primary' : 'action'}>
                    {routeIcon(route.to)}
                  </SvgIcon>
                </Badge>
              </Button>
            );
          })}
          <Box ml={1}>
            <Account />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
