import config from 'config';
import React from 'react';
import { useEffect } from 'react';

declare global {
  interface Window {
    _iub: any;
  }
}

export default function Iubenda({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    if (config.isProduction) {
      window._iub = window._iub || [];

      window._iub.csConfiguration = {
        enableCcpa: true,
        countryDetection: true,
        perPurposeConsent: true,
        enableCMP: true,
        googleAdditionalConsentMode: true,
        isTCFConsentGlobal: false,
        ccpaAcknowledgeOnDisplay: true,
        lang: 'en',
        siteId: 2150127,
        cookiePolicyId: 65214201,
        cookiePolicyInOtherWindow: true,
        consentOnContinuedBrowsing: false,
        banner: {
          acceptButtonDisplay: true,
          customizeButtonDisplay: true,
          acceptButtonColor: '#0073CE',
          acceptButtonCaptionColor: 'white',
          customizeButtonColor: '#212121',
          customizeButtonCaptionColor: 'white',
          rejectButtonDisplay: true,
          rejectButtonColor: '#0073CE',
          rejectButtonCaptionColor: 'white',
          position: 'float-bottom-center',
          backgroundOverlay: true,
          textColor: 'white',
          backgroundColor: '#000001',
        },
      };
    }
  }, []);

  return <>{children}</>;
}
