import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useScript from 'hooks/useScript';
import config from 'config';
import { Package } from 'state/types';
import { useAppState } from 'state';

interface PaypalProps {
  selectedPackage: Package | null;
  onSuccess: (productId: string) => Promise<void>;
  onError: () => void;
}

export default function Paypal({
  selectedPackage,
  onError,
  onSuccess,
}: PaypalProps) {
  const paypalButton = useRef<HTMLDivElement>(null);
  const status = useScript(
    `https://www.paypal.com/sdk/js?client-id=${config.paypal.clientId}`
  );
  const { profile } = useAppState();

  useEffect(
    function () {
      if (
        profile &&
        status === 'ready' &&
        typeof (window as any).paypal !== 'undefined' &&
        selectedPackage
      ) {
        (window as any).paypal
          .Buttons({
            style: {
              color: 'blue',
              shape: 'pill',
              label: 'pay',
              height: 40,
              layout: 'horizontal',
            },
            createOrder: function (data: any, actions: any) {
              return actions.order.create({
                intent: 'CAPTURE',
                purchase_units: [
                  {
                    reference_id: selectedPackage.price_id,
                    custom_id: profile.uid,
                    description: selectedPackage.name,
                    amount: {
                      currency_code: 'USD',
                      value: selectedPackage.unit_amount / 100,
                    },
                  },
                ],
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                },
              });
            },
            onApprove: async function (data: any, actions: any) {
              await actions.order.capture();
              await onSuccess(selectedPackage.id);
            },
            onError,
          })
          .render(paypalButton.current);
      }
    },
    [selectedPackage, status, onSuccess, onError, profile]
  );

  function renderLoader() {
    if (status === 'loading') {
      return (
        <Box p={1}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
  }

  return (
    <>
      {renderLoader()}
      <div ref={paypalButton} />
    </>
  );
}
