import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  illustration: {
    height: 200,
    width: 200,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}));

interface EmptyProps {
  title?: string;
  message?: string | React.ReactNode;
  image?: string;
  children?: React.ReactNode;
}

export default function Empty({
  image = '/static/illustrations/Something_went_wrong.png',
  title,
  message,
  children,
}: EmptyProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.root}>
      <Box display="flex" justifyContent="center" mb={4}>
        {image && (
          <div
            style={{ backgroundImage: `url(${image})` }}
            className={classes.illustration}
          />
        )}
      </Box>
      <Typography align="center" gutterBottom variant="h4">
        {title || t('general.genericError')}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" align="center">
        {message}
      </Typography>
      {children && <Box mt={2}>{children}</Box>}
    </Container>
  );
}
