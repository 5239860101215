import difference from 'lodash.difference';
import Dinero from 'dinero.js';
import isPlainObject from 'lodash.isplainobject';
import { Package, User } from 'state/types';

export function getInitials(name: string) {
  return name
    .split(' ')
    .map((text) => text[0])
    .join('')
    .toUpperCase();
}

export function supportsDeviceChangeEvent() {
  return (
    navigator.mediaDevices &&
    typeof navigator.mediaDevices.ondevicechange !== 'undefined' &&
    typeof navigator.mediaDevices.addEventListener !== 'undefined'
  );
}

export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) =>
      devices.every((device) => !(device.deviceId && device.label))
    )
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) =>
            mediaStream.getTracks().forEach((track) => track.stop())
          );
      }
    });
}

export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export function toPrice(amount: number, currency = 'USD') {
  return Dinero({
    amount,
    currency: currency as Dinero.Currency,
  }).toFormat('$0,0.00');
}

export function renderMonthDate(date: Date) {
  const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
  const month = formatter.format(date);
  return `${month} ${date.getFullYear()}`;
}

export function packageToGA(item: Package) {
  return {
    item_id: item.id,
    item_name: item.name,
    price: item.unit_amount,
    creative_name: item.metadata.credits + ' credits',
    item_brand: 'LingoDuck',
    item_category:
      item.description === 'pro' ? 'subscription' : 'single_purchase',
  };
}

export function onServerError(error: unknown) {
  let code = 'custom/default';
  if (error instanceof Error) {
    code = error.message;
  }
  return `firebaseErrors.${code}`;
}

export function sortLanguages(
  languages: string[],
  languageCodeToName: (language: string) => string
) {
  return languages.sort((a, b) =>
    languageCodeToName(a).localeCompare(languageCodeToName(b))
  );
}

export function profileToTopics(profile: User) {
  const native = difference(
    Object.keys(profile.native),
    profile.noNotificationLanguagesNative
  );

  const practice = difference(
    Object.keys(profile.practice),
    profile.noNotificationLanguagesPractice
  );

  // Topics im listening for
  const nativeTopics = native.map((language) => 'native_' + language);
  const peerTopics = practice.map((language) => 'peer_' + language);
  const teachTopics = practice.map((language) => 'teach_' + language);

  const topics = [...nativeTopics, ...peerTopics, ...teachTopics];

  // Fried Requests to me
  if (profile.notificationSettings.newFriendDesktop) {
    topics.push(`friend_request_receive_${profile.uid}`);
  }

  return topics;
}

export function important(css: string) {
  return `${css} !important`;
}
