import React, { useRef, useState } from 'react';
import {
  Button,
  makeStyles,
  Avatar,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import useLanguage from 'hooks/useLanguage';
import LanguagesPopover from 'components/Language/Popover';

interface LanguageSelectProps {
  selected: string;
  onSelect: (code: string) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '1.1rem',
    fontWeight: 400,
    flex: 1,
    padding: theme.spacing(1.3, 2.3),
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0 2px 5px 2px rgb(45 52 69 / 5%), 0 0 3px 3px rgb(45 52 69 / 2%)',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
  endIcon: {
    marginLeft: 'auto',
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.action.hover}`,
    '& > img': {
      width: 'auto',
      height: 26,
    },
  },
}));

export default function LanguageSelect({
  selected,
  onSelect,
}: LanguageSelectProps) {
  const classes = useStyles();
  const { languageCodeToFlag, languageCodeToName } = useLanguage();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ref = useRef<any>(null);

  let width = 267;

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  if (ref && ref.current) {
    if (!isMobile) {
      width = ref.current.offsetWidth;
    }
  }

  return (
    <>
      <Button
        ref={ref}
        size="large"
        variant="text"
        color="inherit"
        fullWidth
        startIcon={
          <Avatar className={classes.avatar}>
            <img
              alt={languageCodeToName(selected)}
              src={languageCodeToFlag(selected)}
            />
          </Avatar>
        }
        endIcon={<KeyboardArrowDownRounded />}
        onClick={handleClick}
        classes={{ root: classes.button, endIcon: classes.endIcon }}
      >
        {languageCodeToName(selected)}
      </Button>
      <LanguagesPopover
        width={width}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onSelect={onSelect}
        selected={[selected]}
      />
    </>
  );
}
