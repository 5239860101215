import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  FormControl,
  Select,
  MenuItem,
  Box,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { supportedLanguages } from 'i18n';
import Logo from 'components/Logo';
import useLanguage from 'hooks/useLanguage';
import useLocale from 'hooks/useLocale';
import icons from 'config/icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingBottom: '5rem',
    paddingTop: '5rem',
    backgroundColor: theme.palette.background.default,
  },
  languageSelect: {
    minWidth: 200,
  },
  title: {
    lineHeight: 1,
    maxWidth: 900,
    margin: '0 auto',
  },
  message: {
    fontWeight: 500,
    display: 'inline-block',
    lineHeight: 1.8,
  },
  footerMessage: {
    lineHeight: 1.5,
  },
  listItem: {
    backgroundColor: 'transparent !important',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  social: {
    '& > img': {
      height: 20,
    },
  },
  gutters: {
    paddingLeft: 9,
  },
  instagram: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

function Subheader({ children }: { children: string }) {
  return (
    <ListSubheader color="inherit">
      <ListItemText
        primaryTypographyProps={{ variant: 'subtitle1' }}
        primary={children}
      />
    </ListSubheader>
  );
}

function ItemText({ children }: { children: string }) {
  return (
    <ListItemText
      primaryTypographyProps={{
        variant: 'body2',
      }}
      primary={children}
    />
  );
}

export default function Footer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { localePath } = useLocale();
  const { languageCodeToName } = useLanguage();

  const footer = [
    {
      title: 'footer.product.title',
      options: [
        { label: 'footer.product.home', to: '/' },
        { label: 'footer.product.works', to: `/#features` },
        { label: 'footer.product.login', to: '/login' },
        { label: 'footer.product.signup', to: '/welcome' },
      ],
    },
    {
      title: 'footer.company.title',
      options: [
        { label: 'footer.company.terms', to: '/legal/terms' },
        { label: 'footer.company.privacy', to: '/legal/privacy' },
        { label: 'footer.company.cookies', to: '/legal/cookie' },
      ],
    },
  ];

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={4} sm={12}>
            <List component="span">
              <ListItem component="span">
                <Logo />
              </ListItem>
              <ListItem component="span">
                <ListItemText
                  primary={t('home.learn')}
                  primaryTypographyProps={{
                    variant: 'body1',
                    color: 'textSecondary',
                    className: classes.footerMessage,
                  }}
                />
              </ListItem>
              <ListItem component="span">
                <FormControl>
                  <Select
                    value={i18n.language}
                    onChange={(e) => {
                      window.location.pathname = `/${e.target.value as string}`;
                    }}
                    className={classes.languageSelect}
                  >
                    {supportedLanguages.map((option) => (
                      <MenuItem key={option} value={option}>
                        {languageCodeToName(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
            </List>
          </Grid>

          {footer.map((item) => (
            <Grid key={item.title} item md={2} sm={6}>
              <List
                component="ul"
                subheader={
                  <Box mb={2}>
                    <Subheader>{t(item.title)}</Subheader>
                  </Box>
                }
              >
                {item.options.map(({ label, ...option }) => (
                  <ListItem
                    key={option.to}
                    className={classes.listItem}
                    dense
                    button
                    component={RouterLink}
                    to={localePath(option.to)}
                  >
                    <ItemText>{t(label)}</ItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}

          <Grid item md={2} sm={6}>
            <List
              component="ul"
              subheader={
                <Box mb={2}>
                  <Subheader>{t('footer.contact.title')}</Subheader>
                </Box>
              }
            >
              <ListItem
                dense
                button
                component="a"
                href="mailto:support@lingoduck.com"
                rel="noopener"
                className={classes.listItem}
              >
                <ItemText>{t('footer.contact.contact')}</ItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={2} sm={6}>
            <List subheader={<Subheader>{t('footer.social.title')}</Subheader>}>
              <ListItem classes={{ gutters: classes.gutters }}>
                <IconButton
                  component="a"
                  size="small"
                  href="https://www.facebook.com/LingoDuck-103884051624898"
                  target="_blank"
                >
                  <SvgIcon fontSize="small">{icons.facebook}</SvgIcon>
                </IconButton>
                <IconButton
                  component="a"
                  size="small"
                  href="https://instagram.com/lingoduckapp"
                  className={classes.instagram}
                  target="_blank"
                >
                  <SvgIcon fontSize="small">{icons.instagram}</SvgIcon>
                </IconButton>
                <IconButton
                  size="small"
                  component="a"
                  href="https://twitter.com/lingoduck"
                  target="_blank"
                >
                  <SvgIcon fontSize="small">{icons.twitter}</SvgIcon>
                </IconButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
