import React from 'react';
import { useField } from 'formik';
import {
  FormHelperText,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import Radio from 'components/Radio';

type FormikRadioProps = RadioGroupProps & {
  label?: string;
  name: string;
  options: { label: string; value: string }[];
};

export default function FormikRadio({
  label,
  options,
  name,
  ...props
}: FormikRadioProps) {
  const [field, meta] = useField({ name });
  const error = meta.touched && meta.error;

  return (
    <FormControl component="fieldset" error={Boolean(error)}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup {...field} {...props}>
        {options.map((option: any) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
