import React from 'react';
import { useTranslation } from 'react-i18next';
import NestedRoutes from 'components/NestedRoutes';

interface SettingsProps {
  children: React.ReactNode;
}

export default function Settings({ children }: SettingsProps) {
  const { t } = useTranslation();

  const settingsRoutes = [
    { name: t('navigation.profile'), to: '/app/settings/profile' },
    { name: t('navigation.account'), to: '/app/settings/account' },
    { name: t('navigation.languages'), to: '/app/settings/languages' },
    { name: t('navigation.notifications'), to: '/app/settings/notifications' },
    { name: t('navigation.subscription'), to: '/app/settings/pro' },
  ];

  return <NestedRoutes routes={settingsRoutes}>{children}</NestedRoutes>;
}
